.football-match {
    position: relative;

    &:hover {
        background: darken($brightness-97, 4%);

        @include mq(desktop) {
            .football-match__report {
                visibility: visible;

                a {
                    padding-left: $gs-gutter/2;
                    background: darken($brightness-97, 4%);
                }
            }
        }
    }
}

.football-match--live {
    font-weight: 900;

    .football-match__status {
        color: $sport-bright;
    }
}

.football-match--updated {
    .football-team__score,
    .football-teams__battleline {
        color: $sport-bright;
    }
}

.football-matches__container {
    clear: both;
}

.football-matches__date {
    color: $brightness-7;
    display: block;
    margin-top: $gs-baseline/2;
}

.football-match__report {
    position: absolute;
    right: 0;
    visibility: hidden;
}

/* Teams */
.football-match__teams {
    position: relative;
}

.football-teams {
    color: inherit;
    display: block;
    position: relative;
}

.football-match__team {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.football-match__team--home {
    padding-right: $gs-gutter*1.5;
    float: left; /* [1] */
    text-align: right;

    .football-team__score {
        right: ($gs-gutter/4)+1;
    }

    .team-crest {
        float: left;
    }

    .knockout--crest {
        float: none;
        vertical-align: middle;
    }

}

.football-match__team--away {
    padding-left: $gs-gutter*1.5;
    float: right; /* [1] */
    text-align: left;

    .football-team__score {
        left: ($gs-gutter/4)+1;
    }

    .team-crest {
        float: right;
    }

    .knockout--crest {
        float: none;
        vertical-align: middle;
    }
}

.football-team__name {
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
}

.football-team__score {
    color: $brightness-46;
    position: absolute;
    top: 0;
}

.football-team__form {
    margin-top: 2px;
}

.football-teams__battleline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: $brightness-46;

    &:after {
        content: '\2013';
    }
}

.football-match__comments {
    @include fs-textSans(4, true);
    color: $brightness-46;
    text-align: center;
}

.football-match--fixture {
    .football-teams__battleline {
        &:after {
            content: 'v';
        }
    }
}

.is-modern .football-matches tr {
    cursor: pointer;
}

.football-matches__show-more {
    margin-top: $gs-baseline/2;
    outline: none;
}

/* Footnotes */
// [1] See: http://css-tricks.com/fighting-the-space-between-inline-block-elements/
//     It would be nice to not do this, but I felt the tidyness of the HTML was more important in this instant.

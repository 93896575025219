.gu-compopup {
    @include f-textSans;

    padding: $gs-baseline / 2;
    width: 80%;
    top: 44px;
    left: auto;
    right: $gs-baseline / 2;
    z-index: $zindex-modal;
    text-align: left;
    max-width: 16em;
    background-color: #fedd79;

    @include mq(gs-span(5) + $gs-gutter, phablet) {
        padding: $gs-baseline $gs-gutter / 4 * 3;
        top: 62px;
    }

    .gu-compopup__title {
        @include font-size(13, 18);

        margin: 0;
        font-family: inherit;
        font-weight: bold;
    }
}

.gu-compopup__text {
    @include font-size(13, 15);
}

.gu-compopup__link {
    @include font-size(13, 15);

    font-weight: bold;
    color: $brightness-7;
    float: left;
}

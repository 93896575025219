$breaking-news-icon-width: 36px;
$breaking-news-close-icon-width: 32px;

.breaking-news {
    z-index: $zindex-popover;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    transform: translateY(0);
    box-shadow: 0 -1px 2px fade-out(#000000, .8);
    backface-visibility: hidden;

    a,
    .button {
        color: #ffffff;
        text-decoration: none;
    }

    .button {
        padding: 0;
        width: $breaking-news-close-icon-width;
        height: $breaking-news-close-icon-width;
        border-color: #ffffff;

        @include mq(tablet) {
            border-color: $news-pastel;
        }

        &:hover {
            border-color: #ffffff;
        }
    }
}

.breaking-news--fade-in {
    transition: all .5s ease;
}

.breaking-news--hidden {
    opacity: 0;
    transform: translateY(100%);
}

.breaking-news__item {
    display: block;
    padding: 0 0 $gs-baseline;
    background: fade-out($news-dark, 1 - .95);
    transition: background-color .25s ease;

    &:hover {
        background-color: darken($news-dark, 2%);
    }

    & + & {
        border-top: 1px solid fade-out(#ffffff, 1 - .95);
    }
}

.breaking-news__item-content {
    @include gs-container;
    box-sizing: border-box;
    padding: ($gs-baseline * .5) 0 $gs-baseline ($gs-gutter + $breaking-news-icon-width);

    @include mq(wide) {
        .has-page-skin & {
            width: gs-span(12) + ($gs-gutter*2);
        }
    }
}

.breaking-news__item-header {
    position: relative;
    padding-right: gs-span(1) + $gs-gutter * .5;

    .inline-marque-36 {
        position: absolute;
        left: 0 - $breaking-news-icon-width - $gs-gutter / 2;
        top: 0;
    }
}

.breaking-news__item-kicker,
.breaking-news__item-headline {
    @include fs-headline(2);
    line-height: 1.3;

    @include mq(tablet) {
        @include fs-headline(4);
    }
}

.breaking-news__item-kicker {
    color: $news-pastel;
    font-style: normal;
}

.breaking-news__item-headline {
    color: #ffffff;
    font-weight: 500;
    position: relative;
    display: block;

    @include mq(tablet) {
        display: inline;
    }
}

.breaking-news__item-options {
    position: absolute;
    top: $gs-baseline;
    right: 0;
}

// copy of breaking news banner (with blank content) used inline in the body
// to create space for a pinned alert to be scrolled into
.breaking-news--spectre {
    visibility: hidden;
    position: relative;
    z-index: 1;
    transition: none;
}

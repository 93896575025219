.paidfor-band {
    background: $labs-main;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;

    .popup__toggle,
    .popup--paidfor__link,
    .paidfor-band__inner > a {
        // keyboard navigation should have highly visible focus
        outline-color: $highlight-main;
    }

    .has-super-sticky-banner & {
        position: static;
    }

    .paidfor-meta,
    .paidfor-meta__label,
    .paidfor-label {
        display: flex;
    }

    .paidfor-meta__label {
        align-items: center;
    }

    // TODO: switch to focus-visible when browsers support it
    .popup__toggle:focus:not(:hover) {
        outline-style: auto;
        outline-width: 5px;
    }

    .paidfor-meta__label,
    .paidfor-meta__more > .popup__toggle {
        padding: $gs-baseline / 3 $gs-gutter / 2;
    }

    .paidfor-meta__label {
        padding-left: 0;
    }
}

.paidfor-band__inner {
    @include content-gutter();
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    @include mq(tablet) {
        border: 1px solid $brightness-60;
        border-bottom: 0;
        border-top: 0;
    }

    .inline-glabs-logo__svg {
        margin-top: $gs-baseline / 4;
        margin-right: $gs-column-width * 2;

        @include mq(desktop) {
            margin-right: 0;
        }
    }
}

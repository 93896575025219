.crossword__hidden-input-wrapper {
    position: absolute;
}

.crossword__hidden-input {
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: transparent;
    font-size: 16px; // stop zooming on focus
    font-family: $f-serif-text;
    font-weight: 800;
    text-anchor: middle;
}

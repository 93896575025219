@mixin notifcations-button-style($foreground: $news-main, $background: #ffffff) {
    .notifications__toggle {
        border: 0;
        padding: 0;
        display: block;
        background-color: transparent;

        &:hover,
        &:focus {
            outline: 0;
        }
    }
}

$slider-height: 32px;
$slider-clearence: 3px;

.live-notifications__slider {
    float: left;
    position: relative;
    height: $slider-height;
    width: $gs-gutter * 2.5;
    border-radius: $slider-height / 2;
    margin-right: $gs-gutter / 2;
    transition: background .3s ease;

    &:before {
        content: '';
        position: absolute;
        top: $slider-clearence;
        width: $slider-height - ($slider-clearence * 2);
        height: $slider-height - ($slider-clearence * 2);
        background-color: $brightness-97;
        border-radius: 50%;
        transition: all .3s ease;
    }
}

.live-notifications__slider--unsubscribed {
    background-color: $brightness-86;

    &:before {
        left: $slider-clearence;
    }

    .notifications__toggle:hover & {
        background-color: $brightness-46;
    }
}

.live-notifications__slider--subscribed {
    background-color: $news-main;

    &:before {
        left: $slider-clearence * 7;
    }

    .notifications__toggle:hover & {
        background-color: $news-dark;
    }
}

@mixin notifications-button-toned($tone, $foreground: $sport-dark, $background: #ffffff) {
    .tonal--tone-#{$tone} {
        @include notifcations-button-style($foreground, $background);
    }
}

$live-notifications-icon-width: 36px;
$live-notifications-icon-width: 32px;
$notifications-button-height: 28px;


.live-notifications {
    margin-bottom: $gs-baseline /2;
    padding-bottom: $gs-baseline;
    position: relative;
}

.notifications__toggle .inline-icon {
    svg {
        width: 1.143em;
        height: .857em;
    }
}

.notifcations-follow-input--solo {

    .submit-input__icon {
        display: inline-block;
        margin-right: $gs-gutter / 4;
    }
}

.live-notifications__label {
    @include fs-textSans(4);
    color: $news-dark;
    line-height: $slider-height;
    display: none;
}

.live-notifications__label--visible {
    display: block;
}

.live-notifications__label--hidden {
    display: none;
}

.js-live-notifications__label--denied {
    @include fs-textSans(2, true);
    padding: $gs-baseline 0;
    text-align: left;
}

@each $state in (subscribed, unsubscribed) {
    .live-notifications__label--#{$state} {
        display: inline-block;
    }
}

@include notifcations-button-style();
@include notifications-button-toned(live, $news-dark, $brightness-97);
@include notifications-button-toned(dead, $news-dark, $brightness-97);

.live-notifications-denied {
    position: fixed;
    display: block;
    top: .625rem;
    left: 5.275rem;
    width: 26.825rem;
    height: 3.7625rem;
    background: fade-out($news-dark, .05);
    transition: background-color .25s ease;
    opacity: .75;
    z-index: $zindex-notifications-permissions-warning;
    color: $brightness-97;

    @include mq(mobile) {
        width: 17.725rem;
        height: 5.9625rem;
    }

    .button {
        padding: 0;
        width: $live-notifications-icon-width;
        height: $live-notifications-icon-width;
        border-color: #ffffff;

        &:focus,
        &:hover {
            border-color: #ffffff;
        }
    }
}

.live-notifications-denied:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 0;
    position: absolute;
    left: 12px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $news-dark;
    top: -.6225rem;
}

.live-notifications-explainer {

    margin-top: ($gs-baseline / 3) * 4;
    margin-bottom: $gs-baseline;
    background: $news-dark;
    color: $brightness-97;
    display: block;
    padding-left: $gs-gutter/2;
    padding-top: $gs-gutter/2;
    padding-right: $gs-gutter/2;

    .button {
        padding: 0;
        width: $live-notifications-icon-width;
        height: $live-notifications-icon-width;
        border: 1px solid rgba(255, 255, 255, .5);

        @include mq(tablet) {
            border-color: colour(news-pastel);
        }

        &:focus,
        &:hover {
            svg {
                fill: #ffffff
            }
            border-color: #ffffff
        }
    }
}

.live-notifications-explainer:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 0;
    position: absolute;
    left: 5%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $news-dark;
    top: 2.7rem;
}

.live-notifications-explainer-svg {

    svg {
        @include fs-textSans(3);
        width: 100%;
    }
}

.live-notifications-denied__content,
.live-notifications-explainer__content {
    position: relative;
    box-sizing: border-box;
}

.live-notifications-denied__header,
.live-notifications-explainer__header {
    position: relative;

    .inline-marque-36 {
        position: absolute;
        left: 0 - $live-notifications-icon-width - $gs-gutter / 2;
        top: 0;
    }
}

.live-notifications-explainer__header {
    padding-right: $gs-baseline * 2;
}

.live-notifications-denied__header {
    @include fs-textSans(3);
    padding: ($gs-baseline / 3 ) * 2 $gs-baseline * 3 $gs-baseline / 3 ($gs-baseline / 3 ) * 2;

}

.live-notifications-explainer__header-blurb {
    @include fs-textSans(3);
    padding-bottom: $gs-baseline;
}

.live-notifications-explainer__headline {
    @include fs-header(2);
    color: #ffffff;
    position: relative;
    display: block;
}


.live-notifications-denied__item-options,
.live-notifications-explainer__item-options {
    position: absolute;
    margin: -$gs-gutter / 3;
}

.live-notifications-denied__item-options {
    top: $gs-baseline / 2;
    right: $gs-gutter / 5;

}

.live-notifications-explainer__item-options {
    top: $gs-baseline / 50;
    right: -$gs-gutter / 2;

}

/*

Third item. Looks a bit like this:

*********************************
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0

x x x x x x x x x x x x x x x x x
x x x x x x x x x x x x x x x
x x x x x x x x







*********************************

*/


@mixin fc-item--third {
    @include fc-item--standard;

    .fc-item__standfirst {
        display: block;
    }

    &.fc-item--has-cutout {
        @include avatar(3);
        
        @include mq(desktop) {
            @include avatar(4);
        }

        .fc-item__header {
            padding-bottom: gs-height(3) + $gs-baseline * 2;

            @include mq(desktop) {
                padding-bottom: gs-height(4);
            }
        }

        .fc-item__standfirst-wrapper {
            padding-right: gs-height(3);
        }
    }
}

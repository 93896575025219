@mixin d-comment__body {
    word-wrap: break-word;
    word-break: break-word;

    @include mq(768px) {
        line-height: 24px;

        p {
            @include fs-textSans(4);
        }
    }

    p {
        margin-bottom: $gs-baseline;
    }

    blockquote {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 14px;
        border-left: 2px solid #cfcfcd !important; // To override .block blockquote

        &,
        p {
            // Override article styles
            color: $brightness-46;
            font-family: $f-sans-serif-text;
            font-size: inherit;
            line-height: inherit;
        }
    }
}

@mixin d-white-fade {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1) 100%);
}

.creative--fabric-video {
    position: relative;
    height: 250px;
    max-width: 1920px;
    margin: auto;

    > .creative__link,
    .creative__video,
    .creative__poster,
    .creative__alt,
    .creative__layer,
    .creative__slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .creative__video {
        object-fit: cover;

        // this font-family style is for use with the 'object-fit-videos' polyfill.
        font-family: 'object-fit: cover;';
    }

    .creative__video--left {
        object-position: left;
    }

    .creative__poster {
        background-position: center;
        background-repeat: no-repeat;
    }

    .creative__alt--mobile .creative__layer {
        background-repeat: no-repeat;
    }

    .creative__layer  { background-repeat: no-repeat; }
    .creative__layer1 { z-index: 1; }
    .creative__layer2 { z-index: 2; }
    .creative__layer3 { z-index: 3; }

    .creative__slide {
        opacity: 0;
        animation-name: fade-in-out;
        animation-duration: 4.5s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-play-state: paused;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
    }

    .is-animating .creative__slide {
        animation-play-state: running;
    }

    .creative__slide1 {
        animation-delay: 1s;
        animation-duration: 5.25s;
    }

    .creative__slide2 {
        animation-delay: 4.75s;
    }

    .creative__slide3 {
        animation-delay: 7.75s;
    }

    .creative__slide4 {
        animation-name: fade-in;
        animation-delay: 10.75s;
        animation-duration: 5.25s;
    }

    .click_button {
        position: absolute;
        top: 62%;
        left: 50%;
        margin-left: -85px;
    }

    @keyframes fade-in-out {
        0%    { opacity: 0; }
        50%   { opacity: 1; }
        100%  { opacity: 0; }
    }

    @keyframes fade-in {
        0%    { opacity: 0; }
        100%  { opacity: 1; }
    }
}

.copyright-container {
    @include content-gutter();
    padding-bottom: $gs-baseline * 2;
}

.really-serious-copyright {
    color: $brightness-100;
    font-size: 12px;
    padding-top: $gs-baseline * 2;

    @include mq(tablet) {
        padding-top: ($gs-baseline / 2);
    }
}

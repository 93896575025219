.match-summary {
    position: relative;
    display: block;
    color: $brightness-7;
    background-color: $highlight-main;

    &:before {
        @include mq(phablet) {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: calc((100vw - 640px) / -2);
            width: 100vw;
            background-color: $highlight-main;
        }

        @include mq(tablet) {
            left: calc((100vw - 680px) / -2);
        }

        @include mq(desktop) {
            left: -100vw;
        }
    }
}

.match-summary__teams {
    display: flex;
    padding: 6px 10px;

    @include mq(mobileLandscape) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @include mq(tablet) {
        padding-left: 0;
        padding-right: 0;
    }
}

.match-summary__team {
    position: relative;
    width: 50%;
    padding-bottom: $garnett-x-large-button-size + $gs-baseline;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        bottom: 0;
        background-color: mix($brightness-7, $highlight-main, 20%);
        width: 1px;
    }
}

.match-summary__team--home {
    &:before {
        bottom: -6px;

        @include mq($until: leftCol) {
            display: none;
        }
    }
}

.team__name {
    @include fs-header(3);
    line-height: 1.5; // to align with content__labels
    font-weight: 900;
    margin-top: -4px;
}

.team__scorers {
    @include fs-textSans(3);
    color: $brightness-7;
}

.team__scorer {
    margin: 0;
}

.team__crest-wrapper {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: $garnett-large-button-size;
    height: $garnett-large-button-size;
    border-radius: $garnett-x-large-button-size / 2;
    background-color: $brightness-100;
    box-sizing: border-box;
    overflow: hidden;

    @include mq(mobileLandscape) {
        width: $garnett-x-large-button-size;
        height: $garnett-x-large-button-size;
    }
}

.team__crest {
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 8px;
    top: 8px;
    max-width: calc(100% - 16px);
    max-height: calc(100% - 16px);
    margin: auto;
    display: block;
}

.team__score {
    position: absolute;
    bottom: 0;
    left: $garnett-large-button-size - 6px;
    width: $garnett-large-button-size;
    height: $garnett-large-button-size;
    border-radius: $garnett-x-large-button-size / 2;
    border: 1px solid $brightness-7;
    background-color: $highlight-main;

    @include mq(mobileLandscape) {
        left: $garnett-x-large-button-size - 6px;
        width: $garnett-x-large-button-size;
        height: $garnett-x-large-button-size;
    }

    // Technically this is not football-related but for rugby
    .team__score__number {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 51px;
        font-size: 30px;
        display: block;
        text-align: center;
        font-weight: bold;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        @include mq($until: mobileLandscape) {
            transform: scale(.8);
        }
    }
}

.match-summary__comments {
    @include fs-textSans(3);
    position: relative;
    font-style: italic;
    color: $brightness-7;
    padding: 6px 10px;
    border-top: 1px solid mix($brightness-7, $highlight-main, 20%);

    @include mq(mobileLandscape) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @include mq(tablet) {
        margin-right: 6px;
        padding-left: 0;
        padding-right: 0;
    }

    &:before {
        @include mq(leftCol) {
            content: '';
            position: absolute;
            top: -1px;
            left: -10px;
            bottom: 0;
            background-color: mix($brightness-7, $highlight-main, 20%);
            width: 1px;
        }
    }
}

.match-summary__comment + .match-summary__comment {
    margin-left: $gs-gutter / 4;
    padding-left: $gs-gutter / 4;
    border-left: 1px solid mix($brightness-7, $highlight-main, 20%);
}

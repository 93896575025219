@mixin table-football--medium {
    th,
    td {
        min-width: 0;
    }

    .football-stat--form {
        min-width: 0;
    }
}

/* ==========================================================================
   League tables
   ========================================================================== */
.table--football {
    .td {
        vertical-align: middle;
    }

    .table-column--main {
        .team-name {
            display: inline;
            overflow: hidden;
            white-space: normal;
            max-width: 100%;
        }
    }

    .table-column--main {
        max-width: 50px;

        a {
            color: inherit;
        }
    }

    // This makes sure that all table columns are equally spaced
    td {
        min-width: 12px;
    }

    // remove battle line from last row
    // (only happens in truncated tables)
    tr:last-child td {
        border-bottom: 0;
    }

    .football-stat--form {
        min-width: 15*5 // width of status;
    }

    @include mq($until: $table-breakpoint-importance-1) { @include table-football--medium; }
    @include mq($until: $table-breakpoint-importance-2) {
        .team-name:after {
            content: attr(data-abbr);
        }
        .team-name__long {
            @include u-h;
        }
    }

    .football-match--result,
    .football-match--fixture {
        >td {
            padding-left: .3rem;
            padding-right: .3rem;
            &:first-child {
                padding-left: .5rem;
            }
            &:last-child {
                padding-right: .5rem;
            }
        }

        .team-crest {
            margin-right: 0;
        }
    }
}

.team-crest {
    height: 10px;
    margin-right: $gs-gutter/4;
    max-width: 15px;
}

// This is everything on the standalone pages
// i.e. /football/tables or /football/results
.football-table__container {
    @include mq($until: desktop) {
        max-width: gs-span(7);
    }

    margin-bottom: $gs-baseline*3;
    position: relative;
    clear: both;

    .football-matches__date {
        display: none;
    }

    @include mq(leftCol) {
        .table__caption--top {
            @include fs-header(2);
            margin-bottom: ($gs-baseline/3)*4;
            margin-left: ($left-column + $gs-gutter)*-1;
            padding-bottom: ($gs-baseline/3)*4;
            padding-top: ($gs-baseline/3);
            width: $left-column;
            background: none;
            border-bottom: 0;
            border-top: 1px dotted $brightness-86;
            padding-left: 0;
            padding-right: 0;
            position: absolute;
            top: 0;
        }
    }

    @include mq(wide) {
        .table__caption--top {
            margin-left: ($left-column-wide + $gs-gutter)*-1;
            width: $left-column-wide;
        }
    }
}

.c-football-table {
    @include table-football--medium;
    width: 100%;
}

.football__group {
    margin-top: $gs-baseline*2;

    &:first-child {
        margin-top: 0;
    }
}

.table__caption {
    .item__live-indicator {
        top: 2px;
        float: right;
    }
}

.table__caption--bottom {
    padding: 0;
    margin: 0;
    line-height: 1;
}

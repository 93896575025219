.overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $zindex-overlay;

    .is-updating {
        margin-top: ($gs-baseline/3)*20;
    }

    .preload-msg {
        color: #aaaaaa;
    }
}

.overlay--open {
    display: block;
}

/* ==========================================================================
   Hide other page content when overlay is visible
   ========================================================================== */

/*body*/.has-overlay {
    background-color: #222222;

    .l-header,
    #js-context,
    .l-footer,
    .cookie-message,
    .site-message,
    .content,
    .fc-container,
    .l-side-margins {
        display: none;
    }
}

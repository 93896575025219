/* Comments
   ========================================================================== */

.d2-comment-embedded {
    background-color: #ffffff;
    font-family: sans-serif;
    overflow: hidden;
    position: relative;
    margin-bottom: 1.5em;
    padding-top: 15px;
    font-size: 14px;
    padding-left: 28px; // aligns with top level comment's body
    border-left: 8px solid $brightness-86;

    @include mq(tablet) {
        padding-left: 44px;
        border-left-width: 16px;
    }

}

.d2-hidetext {
    text-indent: -9999px;
}

.d2-comment-embedded a {
    color: $sport-dark;
    text-decoration: none;
}

.d2-comment-embedded a img {
    border: 0;
    outline: none;
}

.d2-left-col {
    img {
        float: left;
        margin-right: 15px;
        margin-bottom: 12px;

        @include mq(tablet) {
            width: 60px;
            height: 60px;
        }
    }
}

.d2-right-col {
    position: relative;
    top: -3px;
    padding-right: 16px; // prevent colliding with badge
}

.d2-username {
    font-family: $f-serif-text;

    @include mq(tablet) {
        font-size: 1.28571428571em;
    }
}

.d2-permalink .d2-datetime {
    color: $brightness-46;
    font-size: .85714285714em;
    font-family: $f-sans-serif-text; // Force font when used outside of comment context
}

.d2-permalink .d2-datetime:hover {
    border-bottom: 1px dotted #757575;
}

.d2-pick {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAABBCAMAAADmFHR7AAAAilBMVEX////vkE/vj1Dvj07vkE7vj1DvkE7vkE/vj1Dvj1DvkE6/YAi/Ygjvj02/YAXEZQzvj1DzrHr////ypW/3yKfvkE7wl1n++PTxnmT0s4XxoGfvklHwlVbyo2z4zrL749Pzqnf1upDyomrwlFTxm1/yp3LwmVzxnGH2wZv51b386t798enzqXX63MjTtAjFAAAAEXRSTlMAv0CAz1DvryAQjyCAYDDPMABA3m0AAAFHSURBVHhezZHHcoNAEESRheQcejM5K/v/f8/jLcGyiw72wVV+Bw6v6OkZiJ5f1iGv0du7DFlF0d2vZa4yElnnZNbWAC78AjPJ7ALsZQHwwck9gEwqAJ0r4gCklXsni1uyt8Hej8sWGEwN9PM987rgvDCddJIcpMMVDfybdDazxUidu4v4ZFu3Eq9tGgAfpQFSW5HO3uSjbIHdKFUNtDvVk0tde7fnA1DzNL/5O/5YYmQu2chcCiG0FqX+9IsalkhZauVJwUqpKgZPGs2IU7CSKavqoH6wvGAWMMzbKwuvuBdXwOL2ksK68WXCznTRYnnYR3DmGdDMhDObJphJKCJoh7hilhcx7yKDROsDAOV9pSMtn4RfXgtzYr4UR2pQRyc9/r+M46V8ih7vA7ndRMTak/FDZNlsJ0nRkY/4Kik6QYOtpKjHevUFUmhYZ007m7oAAAAASUVORK5CYII=');
    background-position: 0 0;
    background-repeat: no-repeat;
    height: 66px;
    position: absolute;
    right: 5px;
    top: -2px;
    width: 20px;
}

.d2-badge {
    font: normal 0/0 a;
    color: #ffffff;
    position: absolute;
    top: 16px;
    right: 0;
    text-indent: 0;

    &:after {
        @extend %d-badge-icon;
    }
}

.d2-badge-staff:after {
    content: 'g';
    line-height: .9;
}

.d2-badge-contributor:after {
    content: 'c';
    text-indent: -1px;
}

.d2-body {
    @include d-comment__body;
    clear: both;
}

$table-baseline: 8px;
$table-gutter: 8px;
$table-breakpoint-importance-1: tablet;
$table-breakpoint-importance-2: mobile;
$table-breakpoint-importance-3: 220px;

@mixin table--hide-none {
    th, td { display: table-cell !important; }
}

@mixin table--hide-from-importance-1 {
    .table-column--importance-1 {
        display: none;
    }
}

@mixin table--hide-from-importance-2 {
    .table-column--importance-1,
    .table-column--importance-2 {
        display: none;
    }
}

@mixin table--hide-from-importance-3 {
    .table-column--importance-1,
    .table-column--importance-2,
    .table-column--importance-3 {
        display: none;
    }
}
.table--hide-none { @include table--hide-none; }
.table--hide-from-importance-1 { @include table--hide-from-importance-1; }
.table--hide-from-importance-2 { @include table--hide-from-importance-2; }
.table--hide-from-importance-3 { @include table--hide-from-importance-3; }

table {
    width: 100%;
}

th,
td {
    text-align: left;
}

.table {
    background: $brightness-97;
    border-top: 1px solid $sport-bright;
    border-collapse: inherit;

    th,
    td,
    .table__caption {
        @include fs-textSans(2);
        padding: $table-baseline $table-gutter;
        vertical-align: top;
    }

    th,
    thead td {
        font-weight: 800;
    }

    td {
        border-top: 1px solid darken($brightness-97, 4%);
    }

    abbr {
        border-bottom: 1px dotted $brightness-97;
    }

    // Show / hide functionality
    @include mq($until: $table-breakpoint-importance-1) { @include table--hide-from-importance-1; }
    @include mq($until: $table-breakpoint-importance-2) { @include table--hide-from-importance-2; }
    @include mq($until: $table-breakpoint-importance-3) { @include table--hide-from-importance-3; }
}

.table--responsive-font {
    @include mq(desktop) {
        th,
        td,
        thead td {
            @include fs-textSans(4, true);
            padding: $table-baseline*1.5 $table-gutter*1.5 ($table-baseline);
        }
    }
}

.table--striped {
    td {
        border-top: 0;
    }

    tr:nth-child(odd) > td {
        background-color: darken($brightness-97, 4%);
    }
}

.table-column--sub {
    color: $brightness-46;
}

.table-column--main {
    width: 100%;
}

.table-row--highlight td {
    font-weight: bold;
}

.table-row--divider td {
    border-top: 1px dashed $brightness-7;
}

.table__headline {
    @include fs-bodyHeading(2);
    border-top: 1px dotted $brightness-86;
    padding: $gs-baseline/2 0 $gs-baseline;
}

// We replicate the table style
// then move the caption over the top border
// This is because the caption is outside the flow of the table
.table__caption {
    padding: $table-baseline*2;
    background: $brightness-97;
    border-bottom: 1px solid darken($brightness-97, 4%);
    border-top: 1px solid $sport-bright;
    font-weight: bold;
    margin-bottom: -2px;
    position: relative;
    text-align: left;
    z-index: 2;
}
.table__caption--bottom {
    font-weight: normal;
    border-bottom: 0;
    border-top: 1px solid darken($brightness-97, 4%);
    caption-side: bottom;
    text-align: center;
}

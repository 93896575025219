/*

Standard item. Looks a bit like this:

********************
0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0

x x x x x x x x x x
x x x x x x x x x x x
x x x x x x x x







*********************

*/


@mixin fc-item--standard {
    .fc-item__image-container {
        display: block;
    }

    &.fc-item--has-sublinks-1 .fc-item__media-wrapper {
        display: block;
    }

    &.fc-item--has-cutout .fc-item__media-wrapper {
        display: none;
    }
}

.button--tag {
    @include ellipsis();
    margin-right: $gs-gutter / 5;
    margin-bottom: $gs-row-height / 4.5;
    max-width: gs-span(4);

    @include mq(leftCol) {
        max-width: $left-column;
    }

    @include mq(wide) {
        max-width: $left-column-wide;
    }

    .content__keywords & {
        max-width: none;
    }

    button {
        @include button-colour(
            transparent,
            $brightness-7,
            $brightness-86
        );
        @include button-hover-colour(
            transparent,
            darken($brightness-86, 10%)
        );
        border: 0;
        padding: 0 $gs-baseline / 2;
        margin-left: $gs-gutter / -4;
        border-radius: $gs-gutter * 2 / 5;
    }
}

.show-more-tags {
    vertical-align: bottom;
    display: inline-block;
}

.more-tags { //fix for IE8/9 bug
    display: inline;
}


.feedback__blurb {
    border: 0;
    outline: none;
    display: none;
    background-color: $sport-pastel;
    padding: 10px;
}

.feedback__blurb--selected {
    display: block;
}

.feedback__element {
    margin-top: $gs-baseline * 2;
    margin-bottom: $gs-baseline * 2;
}

.feedback__element input:disabled, .feedback__element textarea:disabled, .feedback__element button:disabled {
    opacity: .5;
}

.feedback__entry {
    border: 1px solid $brightness-86;
    padding: 12px;
    outline: none;
    appearance: none;
    border-radius: 8px;
    display: block;
    width: 80%;
}

.feedback__textarea {
    height: 13em;
}

.feedback__select {
    border: 1px solid $brightness-86;
    padding: 8px;
    margin-top: $gs-baseline * 2;
    outline: none;
    appearance: none;
    border-radius: 20px;
    display: block;
    cursor: pointer;
    background: #ffffff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAxOCI+PHBhdGggZD0iTTIzLjIuN0wxMi43IDkuMWwtMS4xLjktMS4xLS44OThMMCAuNy41IDBsMTEuMSA2LjNMMjIuNyAwbC41Ljd6Ij48L3BhdGg+PC9zdmc+') no-repeat 93% 60%;
    background-size: 16px auto;
    width: 80%;
}

.feedback__alertbox {
    background-color: $news-main;
    color: #ffffff;
    font-size: 1.2em;
    padding: .5em;
    line-height: 1.7em;
    margin-bottom: 2em;
}

.feedback__exclamation {
    margin-right: .5em;
    border: 2px solid #ffe0e0;
    border-radius: 99px;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    line-height: 1.7em;
}

.feedback__alert {
    margin-top: 1em;
    border-top: solid 1px #f97070;
    padding-top: .5em;
}

.feedback__entry--mandatory-failed {
    border-color: $news-main;
}

#feedback__explainer {
    color: $news-main;
}

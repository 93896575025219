.vergadain--branded {
    padding-bottom: $gs-baseline * 8;
}

// general BASIC styles for all the branded badges
.paidfor-container {
    .badge--branded {
        padding-right: 0;
        max-width: 100%;
    }

    .badge__link {
        /* [1] Prevents IE11 bug where the height would go ballooney */
        height: 100%;
    }

    .badge__label {
        text-align: center;
        width: 100%;
    }

    .badge__logo {
        max-width: 100%;
        margin-left: 0;
        height: auto;
    }
}

.dumathoin--capi {
    .dumathoin__row {
        @include mq($until: tablet) {
            flex-direction: column;

            & > * {
                flex-basis: auto;
            }

            & > * + * {
                margin-top: $gs-baseline;
            }

            & > * + .vergadain,
            & > * + * > .vergadain {
                padding-top: $gs-baseline / 2;
            }

            & > * + :nth-child(even)::before {
                content: none;
            }
        }
    }

    .vergadain > :last-child {
        margin-bottom: 0;
    }
}

/* [1] Prevents brand logos floating out the bounds if the cards are hidden */
.paidfor-container {
    margin: 0 $gs-gutter / 2;
    min-height: 80px; /* [1] */

    @include mq($until: tablet) {

        .advert__standfirst {
            min-height: $gs-row-height;
        }

        &:first-child > .vergadain {
            padding-bottom: $gs-baseline / 2;
        }

        &:first-child .advert__title,
        &:first-child .advert__standfirst {
            padding-right: 80px;
            max-width: 520px;
        }

        &:not(:first-child) > .vergadain {
            padding-right: 80px;
            padding-bottom: 0;
            min-height: 80px;
        }

        .badge {
            display: flex;
            flex-direction: column;
            width: 60px;
        }
    }
}

.dumathoin--paidfor {
    @include mq($until: tablet) {
        .dumathoin__stamp {
            position: absolute;
            top: $gs-baseline / 2;
            right: $gs-gutter / 2;
        }

        .inline-glabs-logo > svg {
            width: 90px;
            height: 47px;
        }
    }

    @include mq(mobileLandscape, leftCol) {
        .dumathoin__header {
            flex-wrap: wrap;
        }

        .adverts__kicker {
            flex-basis: 100%;
        }
    }
}

.dumathoin--tone-paidfor {
    background-color: $brightness-93;
    position: relative;

    .dumathoin__header {
        background: $labs-main;
    }

    .inline-icon__svg {
        fill: $labs-main;
    }

    .adverts__logo {
        color: #ffffff;
    }

    .dumathoin__stamp {
        text-align: right;
    }

    .dumathoin__row > * + *::before,
    .dumathoin__row--legacy > .advert::after,
    .dumathoin__2cols::after,
    .dumathoin__3cols::before,
    .dumathoin__3cols::after {
        background: $brightness-86;
    }

    .button {
        @include button-colour($labs-main, null);

        &:hover,
        &:focus,
        &:active {
            @include button-colour(darken($labs-main, 5%), null);
        }
    }
}

.dumathoin-more__button {
    background: $labs-main;
    border-color: $labs-main;

    .inline-icon__svg {
        fill: $brightness-46;
    }

    @include mq(desktop) {
        float: left;

        & + * {
            clear: left;
        }
    }
}

.dumathoin-more[open] {

    .dumathoin-more__button {
        background: none;
        border-color: $brightness-86;
        color: $brightness-7;
    }
}

.adverts--tone-supported {
    background: none;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        transform: translate(-50%, 0);
        border-top: 1px solid $sport-bright;

        @include mq(tablet) {
            width: 740px;
        }

        @include mq(desktop) {
            width: 980px;
        }

        @include mq(leftCol) {
            width: 1140px;
        }

        @include mq(wide) {
            width: 1300px;
        }
    }

    .adverts__logo {
        color: $brand-dark;
    }

    .badge__help {
        color: $brightness-46;
    }
}

.vergadain--capi {
    border-top: 1px solid;
    transition: background .1s;

    .advert__title,
    .advert__meta,
    .advert__standfirst {
        padding: 0 ($gs-gutter / 4);
    }

    .advert__meta {
        margin-top: auto;
        margin-bottom: $gs-baseline / 4;
    }

    .advert__title {
        @include f-textSans;
        font-weight: 400;
        margin-bottom: .5em;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        .inline-icon__svg {
            width: 1.1em;
            height: .75em;
            margin-right: .3em;
        }
    }

    &:not(.vergadain--landscape) {
        > .advert__image-container {
            width: 100%;
        }

        .advert__image {
            width: 100%;

            @include mq(desktop) {
                min-height: $gs-baseline * 11;
            }
        }

        .advert__standfirst:not(:last-child) {
            margin-bottom: $gs-baseline / 2;
        }
    }

    .has-page-skin &:not(.vergadain--landscape) {
        .advert__image {
            @include mq(desktop) {
                min-height: 0;
            }
        }
    }

    .advert__image-container {
        height: auto;
    }

    .has-no-flex &:not(.vergadain--small):not(.vergadain--landscape) {
        padding-top: 0;

        > :first-child {
            padding-top: 66%;
        }
    }
}

.vergadain--paidfor {
    @include f-textSans;
    background: $brightness-97;
    border-top-color: $labs-main;
    width: 100%;

    &:hover,
    &:focus {
        background: darken($brightness-86, 7%);
    }

    .advert__title {
        .inline-icon__svg {
            fill: $brightness-46;
        }
    }

    .dumathoin--legacy-single & {
        background: none;
        border-top: 0;

        &::after {
            background-color: $brightness-46;
        }

        .advert__image-container {
            border-top: 0;
        }
    }
}

.vergadain--supported {
    &.vergadain--text {
        border-top-color: $sport-bright;
        background: $brightness-97;

        &:hover,
        &:focus {
            background: $brightness-93;
        }
    }

    &.vergadain--media {
        border-top-color: $highlight-main;
        background: $brightness-20;
        color: #ffffff;

        &:hover,
        &:focus {
            background: darken($brightness-7, 7.5%);
        }

        .advert__title {
            > .inline-icon .inline-icon__svg {
                fill: $highlight-main;
            }
        }
    }

    > .advert__title {
        @include mq(desktop) {
            @include font-size(get-font-size(headline, 3), get-line-height(headline, 3));
        }
    }

    > .advert__standfirst {
        display: none;
    }
}

.adverts--within-unbranded {
    .fc-item__title,
    .fc-item__standfirst {
        @include f-textSans;
    }
}

.adverts--within-unbranded:not(.fc-item--paid-content) {
    .fc-item__container {
        background-color: $brightness-97;
        &:hover,
        &.u-faux-block-link--hover {
            background-color: $brightness-93;
        }
    }
    .fc-item__container:before {
        background-color: $labs-main !important;
    }
    .inline-icon__svg {
        fill: $labs-main !important;
    }
    .fc-item__title {
        @include f-textSans;
        font-weight: 600;
        color: $brightness-7;
    }

    .fc-item__link,
    .fc-sublink__link {
        &:visited {
            color: #5c5c5c;
        }
    }

    .fc-item__kicker {
        color: $labs-main;
    }

    .fc-item__content {
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
        // IE 11 hates shorthand flex CSS, and does something different to the
        // other browsers, so we have to list the props out in this way
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        @include mq(phablet, tablet) {
            flex-direction: row;
        }
    }

    .fc-item__standfirst {
        @include f-textSans;
        color: #757575;
    }

    .badge {
        display: flex;
        align-self: flex-end;
        justify-content: flex-end;
        pointer-events: none;
        flex-shrink: 0;
        flex-direction: column;
        align-items: flex-end;
        margin-left: $gs-gutter / 2;
        @include mq($until: phablet) {
            flex-direction: row;
        }
    }

    .badge__label {
        color: #757575;
        text-align: center;
        align-self: center;
        @include mq($until: phablet) {
            padding-right: $gs-gutter / 2;
        }
    }

    .badge__link {
        @include mq($until: tablet) {
            width: $gs-gutter * 3;
        }
    }

    .badge__logo {
        max-width: 100%;
        margin-left: 0;
    }

    .badge--no-image {
        margin-bottom: $gs-baseline/2;
    }

    .badge__sponsor-name {
        @include f-textSans;
        color: $brightness-7;
    }

    .inline-icon__svg {
        width: 1.1em;
        height: .75em;
        margin-right: .3em;
    }
}

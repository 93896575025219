/**
* Most Popular FRONTS customisation: change layout to accommodate MPU (with or without tabs)
*/

.tabs--mostpop .tabs__content,
.fc-slice--popular {

    @include mq(desktop) {
        display: table;
        display: flex;
    }

    .fc-slice__popular-mpu {
        @include mq(desktop) {
            padding-left: $gs-baseline;
        }
    }
}

.tabs--mostpop--without-mpu {
    @include mq($from: desktop) {
        width: 100%;
        max-width: gs-span(8);
    }
}

/**
 * Multiple tab sizing
 *
 * Used for cases where more than 2 tabs are wanted (eg not two 50% width tabs)
 */
.tabs__container {
    max-width: gs-span(6);
}
.tabs__container--multiple {
    margin-bottom: 10px;
    max-width: 100%;

    @include mq(desktop) {
        .tab--stats {
            display: none;
        }
    }
}

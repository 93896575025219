/* ==========================================================================
   Profile comment activity
   ========================================================================== */

@include mq-add-breakpoint(activityLeftCol, mq-get-breakpoint-width(desktop));
$disc-avatar-size: 44px;


/* Comment
   ========================================================================== */

.disc-comment {
    @include fs-textSans(4);
    padding-bottom: $gs-baseline/2;

    @include mq(activityLeftCol) {
        &:first-of-type {
            border-top: 0;
        }
    }
}
.disc-comment__meta {
    @include fs-textSans(1);
    margin-bottom: $gs-baseline*1.5;
    color: $brightness-46;

    @include mq(activityLeftCol) {
        float: right;
    }
}
.disc-comment__date-published {
    @include fs-textSans(1);
    display: block;
}
.disc-comment__permalink {
    color: $brightness-46;
}
.disc-comment__recommend {
    position: absolute;
    top: $gs-baseline/2;
    right: 0;
    width: auto;
    cursor: default;
}
.disc-comment__recommend-count {
    display: inline-block;
    margin-right: $gs-gutter/4;

    .disc-comment__recommend--active & {
        color: $opinion-main;
    }
}
.disc-comment__recommend-button {
    @include circular;
    display: inline-block;
    vertical-align: middle;
    height: 19px;
    width: 18px;
    background-color: $brightness-46;

    .i {
        vertical-align: middle;
    }

    .disc-comment__recommend--open & {
        background-color: $sport-dark;
    }

    .disc-comment__recommend--active & {
        background-color: $opinion-main;
    }
}

.disc-comment__body {
    margin-bottom: $gs-baseline;

    blockquote {
        margin: $gs-baseline $gs-gutter;
        padding-left: $gs-gutter/2;
        border-left: 3px solid transparentize($brightness-7, .75);
    }
}
.disc-comment__view-discussion {
    @include fs-textSans(2);
    display: inline-block;
}


/* Profile: user info, date
   ========================================================================== */

.disc-profile {
    @include fs-textSans(4);
}
.disc-profile__avatar {
    @include circular;
    float: left;
    overflow: hidden;
    height: $disc-avatar-size;
    width: $disc-avatar-size;
}
.disc-profile__avatar-image {
    height: 100%;
    width: 100%;
}
.disc-profile__user-info {
    padding-left: $disc-avatar-size + ($gs-gutter/2);
}
.disc-profile__badges {
    @include fs-textSans(1);
    text-transform: uppercase;
}
.disc-profile__date {
    @include fs-textSans(1);
    color: $brightness-46;
}

.l-list {
    width: 100%;

    @include mq(tablet) {
        display: flex;
        flex-wrap: wrap;
    }
}

.l-list__item {
    float: left;

    flex-grow: 0;
    flex-basis: 100%;
}

// We only need this functionality for 2 & 4 column rows.
@for $column from 2 through 4 {
    @for $span from 1 through 5 {
        .l-row--cols-#{$column} {
            .l-row__item--span-#{$span} {
                @include mq(tablet) {
                    width: (100% / $column) * $span;
                    float: left;

                    flex: $span 1 auto;
                }
            }
        }
    }
}

@for $column from 1 through 4 {
    .l-list--columns-#{$column} {
        .l-list__item {
            @include mq(tablet) {
                width: 100% / $column;

                &:nth-child(#{$column + 'n+1'}) {
                    clear: both;

                    &:before {
                        border: 0;
                    }
                }

                &:nth-last-child(#{$column}):nth-child(#{$column + 'n+1'}),
                &:nth-last-child(#{$column}):nth-child(#{$column + 'n+1'}) ~ .l-list__item {
                    padding-bottom: 0;
                }


                flex-basis: (100% / $column);

            }
        }
    }
}

.treats__container {
    list-style-type: none;
    margin: $gs-baseline 0;
    display: none;
    position: absolute;
    bottom: 11px;

    .fc-container--has-show-more & {
        bottom: 41px;
    }

    .fc-container--rolled-up & {
        display: none;
    }

    @include mq(leftCol) {
        display: block;
    }

    @include mq(wide) {
        .has-page-skin & {
            display: none;
        }
    }
}

.treats__treat {
    @include fs-textSans(1);
    @include button-colour(
        #ffffff,
        $brightness-7,
        $brightness-86
    );
    @include button-hover-colour(
        #ffffff,
        darken($brightness-86, 10%)
    );
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    font-weight: 400;
    border-width: 1px;
    border-style: solid;
    border-right-style: none;
    border-bottom-style: none;
    text-decoration: none;
    max-width: gs-span(4);
    line-height: $gs-baseline;
    padding: ($gs-baseline / 4) 0 ($gs-baseline / 6) ($gs-baseline / 2);
    width: 190px;

    @include mq(leftCol) {
        max-width: $left-column;
    }

    @include mq(wide) {
        max-width: $left-column-wide;
    }

    &:hover,
    &:focus,
    &:active {
        color: $brightness-7;
        text-decoration: none;
    }
}

.treats__list-item {
    margin-top: $gs-baseline * 2/3;

    &:first-child {
        margin-top: 0;
    }
}

.treats__crossword {
    display: block;
    margin-top: $gs-baseline * 4/3;
    margin-bottom: $gs-baseline * 2/3;
}

.treats__crossword-link {
    &:hover,
    &:focus,
    &:active {
        .treats__treat {
            color: $brightness-7;
            border-color: darken($brightness-86, 10%);
        }
    }
}

.match-summary__rugby-table {
    @include fs-textSans(3);
    color: $brightness-46;

    tr {
        border: solid $brightness-93;
        border-width: 1px 0;
    }

    td,
    th {
        padding: $gs-baseline / 2;
    }
}

.match-summary__rugby-table__home-data,
.match-summary__rugby-table__away-data {
    width: 40%;
}

.match-summary__rugby-table__away-data,
.match-summary__rugby-table__away-team-name {
    text-align: right;
}

.match-summary__rugby-table__score-type {
    text-align: center;
}

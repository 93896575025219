.creative__tracking-pixel {
    position: absolute;
    width: 1px;
    height: 1px;
}

.creative__cta {
    display: block;
}

.creative--scrollable-mpu {
    .creative--scrollable-mpu-inner {
        margin: 0 auto;
        width: 300px;
        height: 250px;
        overflow: hidden;
        position: relative;
    }
    .creative--scrollable-mpu-image,
    .creative--scrollable-mpu-static-image {
        display: block;
        height: 100%;
        width: 300px;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
    }
    .creative--scrollable-mpu-image {
        border: 1px solid $brightness-86;
        background: none 0 0 repeat;

        &.creative--scrollable-mpu-image-fixed {
            background-attachment: fixed;
            /*TODO consider using solution from https://www.youtube.com/watch?v=QU1JAW5LRKU*/
            background-repeat: repeat;
            background-position: 50% 0;
        }
        &.creative--scrollable-mpu-image-parallax {
            background-attachment: scroll;
            background-repeat: repeat-y;
            height: 500px;
        }
    }
    .creative--scrollable-mpu-static-image {
        z-index: 1;
        background-repeat: no-repeat;
    }
}

.creative--expandable {
    margin-bottom: $gs-baseline;

    .facia-container__inner {
        border-top: 0;
        padding-top: 0;
    }
    .ad-slot__label--wrapper {
        @include mq($until: tablet) {
            width: auto;
        }
    }
    .ad-slot__label {
        text-align: left;
    }
    .ad-exp--expand {
        height: 0;
        position: relative;
        overflow: hidden;
        transition: height 1s;
    }
    .ad-exp--expand .facia-container__inner {
        height: 100%;
        position: relative;

        @include mq($until: tablet) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
    .ad-exp__close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 100px;
        border: 1px solid #ffffff;
        background-color: #000000;
        margin: 5px;
        padding: 0;
        z-index: 3;
        height: 32px;
        width: 32px;
        transform: rotate(45deg);
        transition: all 1s ease;
        box-shadow: 0 0 1px 0px #ffffff inset, 0 0 1px 0px #ffffff;

        &:focus {
            outline-color: transparent;
            outline-style: none;
        }
        &.button-spin {
            transform: rotate(360deg);
        }
    }

    .expandable_video {
        position: absolute;
        z-index: 10;
        margin-top: $gs-baseline/2;

        &.expandable_video--horiz-pos-left {
            left: 0;
        }
        &.expandable_video--horiz-pos-center {
            left: 50%;
        }
        &.expandable_video--horiz-pos-right {
            left: auto;
            right: 0;
        }
    }

    .ad-exp__open-chevron {
        @include fs-textSans(1);
        position: absolute;
        z-index: 11;
        bottom: 0;
        left: 50%;
        border: 1px solid #ffffff;
        border-width: 1px 1px 0;
        background: $brightness-7;
        color: #ffffff;
        opacity: .75;
        padding: $gs-baseline/2 0 0;
        width: gs-span(1);
        margin-left: $gs-gutter*-1.5;
        text-align: center;
        outline: none;
        height: 39px;
        border-radius: $gs-gutter $gs-gutter 0 0;
        box-shadow: 0 0 1px 0px #ffffff inset, 0 0 1px 0px #ffffff;

        .inline-arrow-down {
            transition: all 1s ease;
            display: block;
            margin: -12px auto 2px;
        }

        &.chevron-down .inline-arrow-down {
            transform: rotate(180deg);
            transition: all 1s ease;
        }

        &.chevron-up {
            animation: chevronanimation 3s ease-in-out 2;
        }

    }

    .slide-1 {
        z-index: 1;
        overflow: hidden;
    }

    .ad-exp-collapse__slide {
        position: relative;
    }

    .ad-exp__layer {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        &.ad-exp__layer1 {
            z-index: 1;
        }

        &.ad-exp__layer2 {
            z-index: 2;
        }

        &.ad-exp__layer3 {
            z-index: 3;
        }
    }

    .ad-exp--expand-scrolling-bg {
        position: absolute;
        top: 0;
        height: 500px;
        width: 100%;

        &.ad-exp--expand-scrolling-bg-fixed {
            background-attachment: fixed;
            /*TODO consider using solution from https://www.youtube.com/watch?v=QU1JAW5LRKU*/
        }

        &.ad-exp--expand-scrolling-bg-parallax {
            background-attachment: scroll;
        }
    }
}

.creative--expandable-video {

    .facia-container__inner {
        border-top: 0;
        padding-top: 0;
    }
    .ad-slot__label {
        text-align: left;
    }
    .ad-exp--expand {
        height: 0;
        position: relative;
        overflow: hidden;
        transition: height 1s;
    }
    .ad-exp__close-button {
        position: absolute;
        top: 34px;
        right: 10px;
        border-radius: 100px;
        border: 1px solid #ffffff;
        background-color: #000000;
        margin: 5px;
        padding: 0;
        z-index: 3;
        height: 32px;
        width: 32px;
        transform: rotate(45deg);
        transition: all 1s ease;
        box-shadow: 0 0 1px 0px #ffffff inset, 0 0 1px 0px #ffffff;

        &:focus {
            outline-color: transparent;
            outline-style: none;
        }
        &.button-spin {
            transform: rotate(360deg);
        }
    }

    .ad-exp__open-chevron {
        @include fs-textSans(1);
        position: absolute;
        z-index: 11;
        bottom: -10px;
        left: 50%;
        border: 1px solid #ffffff;
        border-width: 1px 1px 0;
        background: $brightness-7;
        color: #ffffff;
        opacity: .75;
        padding: $gs-baseline/2 0 0;
        width: gs-span(1);
        margin-left: $gs-gutter*-1.5;
        text-align: center;
        outline: none;
        height: 39px;
        border-radius: $gs-gutter $gs-gutter 0 0;
        box-shadow: 0 0 1px 0px #ffffff inset, 0 0 1px 0px #ffffff;

        .inline-arrow-down {
            transition: all 1s ease;
            display: block;
            margin: -12px auto 2px;
        }

        &.chevron-down .inline-arrow-down {
            transform: rotate(180deg);
            transition: all 1s ease;
        }

        &.chevron-up {
            animation: chevronanimation 3s ease-in-out 2;
        }

    }

    .slide-container {
        width: 100%;
        margin: 0 auto;
        position: relative;

        @include mq(wide) {
            width: 1300px;
        }
    }

    &.full-width-video {
        .video-container__inner {
            width: 100%;
        }

        .slide-video .slide-container {
            @include mq(wide) {
                width: 100%;
            }
        }
    }

    .slide-video {
        position: absolute;
        top: 24px;
        width: 100%;
        z-index: 3;
        visibility: hidden;
        transition: all 1s, visibility 0s;

        > .slide-container {
            display: flex;
            justify-content: flex-end;
        }
    }

    .slide-video__expand {
        visibility: visible;
    }

    .video-container__inner {
        position: relative;
    }

    &.right-aligned-video .video-container__inner {
        width: 100%;

        @include mq(1024px) {
            width: 768px;
        }
    }

    .expandable-video {
        display: block;
    }

    .slide-1 {
        z-index: 1;
        overflow: hidden;
    }

    .ad-exp-collapse__slide {
        position: relative;
    }

    .ad-exp__layer {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        &.ad-exp__layer1 {
            z-index: 1;
        }

        &.ad-exp__layer2 {
            z-index: 2;
        }

        &.ad-exp__layer3 {
            z-index: 3;
        }
    }

    .has-no-flex & {
        .slide-video {
            > .slide-container {
                display: block;
                overflow: hidden;
            }
        }

        &.right-aligned-video .video-container__inner {
            float: right;
        }
    }

    .slide-video:not(.slide-video__expand) .expandable-video {
        display: none;
    }
}

.creative--expandable-video-v2 {

    &:not(.creative--open) {
        .ad-exp--expand {
            cursor: pointer;
        }
    }

    &.right-aligned-video .video-container__cta {
        display: none;

        @include mq(1024px) {
            display: block;
            flex: 1;
        };
    }

    .creative__cta {
        position: absolute;
        right: 10px;
        opacity: 0;
        transition: none;

        > img {
            display: block;
        }
    }

    .slide-video__expand .creative__cta {
        transition: opacity .2s 3s;
        opacity: 1;
    }

    .creative__cta--position-top    { top:    10px; }
    .creative__cta--position-bottom { bottom: 10px; }

    .ad-exp__open--container {
        @include mq(wide) {
            right: calc(50% - 640px);
        }
    }

    .has-no-flex & {
        &.right-aligned-video .video-container__cta {
            @include mq(1024px) {
                display: block;
                position: absolute;
                width: calc(100% - 768px);
                top: 0;
                bottom: 0;
                left: 0;
            }

            @include mq(wide) {
                width: 532px;
            }
        }
    }

}

.creative--fabric-v1__label {
    text-align: left;

    .ad-slot--fabric-v1 & {
        width: auto;
        display: block;
        margin-top: 0;
    }

    .fc-container__inner {
        padding-top: 0;
        border-top: 0;
    }
}

.creative--fabric-v1-bg-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 0 !important;

    .ad-scrolling-bg {
        position: absolute;
        top: 0;
        height: 500px;
        width: 100%;
        background-repeat: no-repeat;
    }

    .ad-scrolling-bg-parallax {
        background-attachment: scroll;
    }

    .fabric-v1_layer {

        .is-modern & {
            &.ad-scrolling-text-hide {
                background-position: 0 -250px;
            }
            &.ad-scrolling-text-hide-left {
                background-position: left -250px;
            }
            &.ad-scrolling-text-hide-right {
                background-position: right -250px;
            }
            &.ad-scrolling-text-hide-centre {
                background-position: center -250px;
            }
            &.ad-scrolling-text-animate {
                animation: textanimation 1.4s ease .4s;
                animation-fill-mode: forwards;
            }
            &.ad-scrolling-text-animate-left {
                animation: textanimation 1.4s ease .4s;
                animation-fill-mode: forwards;
            }
            &.ad-scrolling-text-animate-right {
                animation: textanimation-right 1.4s ease .4s;
                animation-fill-mode: forwards;
            }
            &.ad-scrolling-text-animate-centre {
                animation: textanimation-centre 1.4s ease .4s;
                animation-fill-mode: forwards;
            }
        }
    }

    .ad-slot__label {
        position: relative;
        text-align: left;
    }

    .fc-container__inner {
        padding-top: 0;
        border-top: 0;
    }
}

.creative--fabric-v1 {
    height: 250px;

    .gs-container {
        height: 100%;
    }

    .fabric-v1_video {
        position: absolute;
        z-index: 10;
        margin-left: 0;
        margin-top: 0;

        &.fabric-v1_video--vert-pos-top {
            top: 0;
        }
        &.fabric-v1_video--vert-pos-center {
            top: 50%;
            /* Magic number of video height/2 */
            margin-top: -115px;
        }
        &.fabric-v1_video--vert-pos-bottom {
            top: auto;
            bottom: 0;
        }
        &.fabric-v1_video--horiz-pos-left {
            left: 0;
        }
        &.fabric-v1_video--horiz-pos-center {
            /* Magic number of video width/2 */
            margin-left: -205px;
            left: 50%;
        }
        &.fabric-v1_video--horiz-pos-right {
            left: auto;
            right: 0;
        }
    }

    .fabric-v1_layer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-repeat: no-repeat;
    }

    .fabric-v1_layer2 {
        z-index: 2;
    }

    .fabric-v1_layer3 {
        z-index: 3;
    }
}

.creative--fabric-expanding-v1 {
    @include mq($until: tablet) {
        // Smaller video should not flush to container bottom
        .expandable_video {
            bottom: $gs-row-height / 2;
        }
    }
}

@include mq(tablet) {
    .top-banner-ad-container .ad-slot--top-banner-ad__fabric-v1 {
        height: auto;
        padding: 0;
    }
}


@keyframes textanimation {
    0% {
        background-position: 0 -250px;
    }

    75% {
        background-position: 0 0;
    }

    100% {
        background-position: 0 0;
    }
}

@keyframes textanimation-right {
    0% {
        background-position: right -250px;
    }

    75% {
        background-position: right 0;
    }

    100% {
        background-position: right 0;
    }
}

@keyframes textanimation-centre {
    0% {
        background-position: center -250px;
    }

    75% {
        background-position: center 0;
    }

    100% {
        background-position: center 0;
    }
}

@keyframes chevronanimation {
    0%, 50%, 80%, 100% {
        transform: (translateY(0));
    }

    60% {
        transform: (translateY(-10px));
    }

    70% {
        transform: (translateY(5px));
    }
}

// In DCR, these styles are injected via JS via:
// static/src/javascripts/projects/commercial/modules/messenger/background.js
// mark: 0bf74539-5466-4907-ae7b-c0d8fc41112d
.creative__background-parent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    /* This will clip the fixed-positioned child to the boundaries of its parent */
    clip: rect(0, auto, auto, 0);

    .creative__background {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .creative__background--parallax {
        position: absolute;
    }

    .creative__background--fixed {
        position: fixed;
    }
}

.creative__background-parent-interscroller {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    clip: rect(0, auto, auto, 0);

    .creative__background {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .creative__background--interscroller {
        width: 100%;
        top: 0;
        left: 0;
        margin-top: 24px;
        position: fixed;
    }
}

.creative__background-parent-interscroller::before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    border-bottom: 1px solid $brightness-86;
}

.ad-slot__label.sticky {
    position: sticky;
    top: 0;
}

.ad-slot__scroll {
    @include font-size(12, 20);
    height: $mpu-ad-label-height;
    background-color: $brightness-97;
    padding: 0 ($gs-baseline/3)*2;
    border-top: 1px solid $brightness-86;
    color: $brightness-46;
    text-align: left;
    box-sizing: border-box;
    font-family: $f-sans-serif-text;
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
}


/*

Media list item. Looks a bit like this:

***********************************************
0000000  x x x x x x x x x x x x x x x x x x x
0000000  x x x x x x x x x x x x x x x x x x x x
0000000
***********************************************

 */

@mixin fc-item--list-media ($text-lines-per-media-object: 4, $headline-size: 2) {
    @include fc-item--list;

    // calculate image width
    $media-ratio: 5 / 3;
    $item-vertical-padding: $gs-baseline / 4;
    $estimate-item-min-height: (($item-vertical-padding * .6) + get-line-height(headline, $headline-size) * $text-lines-per-media-object);
    $image-width: normalize-width($estimate-item-min-height * $media-ratio);
    $item-min-height: $image-width / $media-ratio;
    $mobile-media-padding: ($gs-baseline / 2);

    .fc-item__content {
        min-height: $item-min-height + ($mobile-media-padding * 2);
        position: relative;

        @include mq($from: tablet) {
            min-height: $item-min-height;
        }
    }

    &.fc-item--has-image {
        .fc-item__content {
            padding-left: $image-width + ($mobile-media-padding * 2);

            @include mq(tablet) {
                padding-left: $image-width + $mobile-media-padding;
            }
        }
    }

    .fc-item__media-wrapper,
    .fc-item__image-container {
        display: block;
    }

    .fc-item__media-wrapper,
    .fc-item__video-fallback {
        position: absolute;
        margin-left: $mobile-media-padding;
        width: $image-width;
        margin-top: $mobile-media-padding;
        margin-bottom: $mobile-media-padding;

        @include mq(tablet) {
            margin-top: 0;
            margin-left: 0;
        }
    }

    .fc-item__header {
        padding-bottom: 0;
    }

    &[class*='fc-item--has-sublinks'] {
        .fc-item__footer--vertical {
            display: none;
        }

        .fc-item__footer--horizontal {
            display: block;
            margin-right: $gs-gutter *.25;
            margin-left: $gs-gutter *.25;
        }
    }

    &.fc-item--has-cutout {
        @include avatar(2.6);

        .fc-item__avatar {
            display: block;
        }

        .fc-item__content {
            min-height: gs-height(2.6);
            padding-left: $gs-gutter * .25;
            padding-right: gs-span(1.5);
        }

        .fc-item__standfirst {
            display: none;
        }
    }

    &.fc-item--list-media-mobile {
        @include mq($until: tablet) {
            .fc-item__video {
                width: $image-width;

                .fc-item__video-play {
                    padding-bottom: 60%;
                }
            }
        }
    }
}

.element-rich-link--not-upgraded.element-rich-link--tag {
    // only display tag based rich links when they are fully upgraded (as we have no link text)
    // not using `display: none` here so spacefinder still sees it
    height: 0;
    width: 0;
    margin: 0;
    border: 0;
    overflow: hidden;

}

// Base rich link styles
//----------------------------------

.rich-link {
    @include stars();

    .stars {
        padding: $gs-baseline / 6;
        display: inline-block;
        margin-top: $gs-baseline / 2;
        margin-left: 0;
    }

    .content--article & {
        .star__item__svg {
            width: 1em;
            height: 1em;
        }
    }

}

.rich-link__image-container {
    transition: background-color .25s ease;
    background-color: rgba(0, 0, 0, .1);

    .responsive-img {
        transition: opacity .25s ease;
    }
}

.rich-link .u-faux-block-link--hover {
    background-color: $brightness-93;

    .rich-link__image-container {
        background-color: #000000;
        .responsive-img {
            opacity: .8;
        }
    }
}

.rich-link__byline {
    @include fs-header(3);
    padding-right: $gs-gutter;
}

@include mq(tablet) {
    .rich-link--has-byline-pic .rich-link__read-more {
        position: absolute;
        bottom: 0;
    }
}

.rich-link__live {
    font-weight: normal;
}

.rich-link--gallery {
    .rich-link__header {
        padding-top: $gs-baseline / 4;
    }
}

.rich-link.tone-live--item .live-pulse-icon {
    @include mq(mobileLandscape) {
        margin-bottom: 1px;
    }
}

.rich-link__title .inline-quote {
    fill: $brightness-46;
    svg {
        height: 14px;
        width: 23px;
    }
}

// In-article styles
//-----------------------

.element-rich-link {
    @include mq(tablet) {
        .rich-link--has-byline-pic .rich-link__container {
            padding-bottom: $gs-baseline * 10;
        }
    }

    @include mq(leftCol) {
        &.element--supporting .rich-link--has-byline-pic .rich-link__container {
            padding-bottom: ($gs-baseline * 2) + 8;
        }
    }

    .blog & {
        .rich-link__image-container {
            display: none;
        }
        .rich-link--gallery,
        .tone-feature--item {
            max-width: gs-span(6) + (2 * $gs-gutter);
            .rich-link__image-container {
                display: block;
            }
        }
    }
}

.blog .element-rich-link {
    float: none;
    width: auto;
}

.rich-link--paidfor {
    &.tone-media--item,
    & {
        .rich-link__container {
            background-color: $brightness-97;
            &:hover,
            &.u-faux-block-link--hover {
                background: darken($brightness-86, 7%);
            }
            &:before {
                background-color: $labs-main;
            }
            .rich-link__header,
            .rich-link__read-more-text {
                @include f-textSans;
                color: $brightness-7;
            }
            .rich-link__arrow-icon {
                fill: $labs-main;
            }
        }
    }
    .rich-link__title {
        color: $brightness-7;
    }
}
.rich-link__branding {
    @include fs-textSans(1);
    color: $brightness-46;
    font-weight: bold;
    margin: -$gs-baseline / 2 $gs-gutter / 4 $gs-baseline;
}

$sfl-button-height: 30px;

// size etc
.save-for-later__button {
    @include button-height($sfl-button-height);
    font-size: get-font-size(textSans, 1);
    border: 0;
    background-color: transparent;
    padding: 0;
    color: $sport-dark;

    .inline-icon {
        text-align: center;
        margin: 0;
        margin-right: .5em;
        width: $sfl-button-height;
        height: $sfl-button-height;
        border: 1px solid $sport-dark;
        fill: $sport-dark;

        svg {
            width: 8px;
        }
    }
}

.save-for-later__button--saved,
.save-for-later__button--save:hover,
.save-for-later__button--save:focus {
    .inline-icon {
        fill: #ffffff;
        background-color: $sport-dark;
    }
}

// media tone
.tonal--tone-media {
    .save-for-later__button {
        color: $brightness-86;

        .inline-icon {
            border-color: $brightness-46;
            fill: $brightness-86;
        }
    }

    .save-for-later__button--saved,
    .save-for-later__button--save:hover,
    .save-for-later__button--save:focus {
        .inline-icon {
            fill: $brightness-7;
            border-color: $brightness-86;
            background-color: $brightness-86;
        }
    }
}

// button labels per state
.save-for-later__label {
    display: none;
}

@each $state in (save, saved, remove) {
    .save-for-later__button--#{$state} {
        .save-for-later__label--#{$state} {
            display: inline;
            .meta__extras--crossword & {
                display: none;
            }

        }
    }
}

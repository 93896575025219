.match-stat {
    padding-bottom: ($gs-baseline/3)*2;
    margin-bottom: 0;

    span {
        display: inline-block;
    }
}

.match-stat__item {
    float: left;
    width: 49%;

    & + & {
        float: right;
        text-align: right;
    }
}

.team__results {
    min-width: $gs-gutter*1.5;
    font-size: 0;
    white-space: nowrap;
}

.team-result {
    display: inline-block;
    top: 0;
    vertical-align: middle;
    border-radius: 4px;
    margin-left: 2px;
    height: $gs-baseline;
    width: 4px;
    cursor: help;

    &:first-child {
        margin-left: 0;
    }
}

.team-result--won {
    background-color: $green-bold;
    margin-top: - $gs-baseline / 2;
}

.team-result--drew {
    background-color: $brightness-46;
    height: 4px;
}

.team-result--lost {
    background-color: $news-main;
    margin-top: $gs-baseline / 2;
}

/*

Three quarter item. Looks like a wide standard, a bit like this:

************************************************************************************************
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0

************************************************************************************************

*/

@mixin fc-item--three-quarters {
    @include fc-item--horizontal(66%); // Works out as same width as 6 col on desktop and 4.5 col on tablet to align with other items

    .fc-item__header {
        @include mq(desktop) {
            @include fs-headline(4, true);
            @include fs-headline-quote(4);
            @include headline-boost(5);

            .fc-item__action-kicker-wrapper {
                @include fs-action-kicker(4);
            }
        }
    }

    .fc-item__standfirst {
        @include mq(desktop) {
            display: block;
        }
    }

    .fc-item__liveblog-blocks {
        padding-right: 0;
    }

    &.fc-item--has-sublinks-3 {
        @include fc-sublinks--below;
    }

    &.fc-item--has-cutout {
        @include avatar(6);

        @include mq(desktop) {
            @include avatar(7);
        }

        .fc-item__container {
            min-height: gs-height(7);
            flex-direction: column;
        }

        .fc-item__header {
            @include fs-headline(5, true);
            @include fs-headline-quote(5);
            @include headline-boost(6);
            padding-bottom: gs-height(3);
            margin-bottom: $gs-gutter * .25;

            .fc-item__action-kicker-wrapper {
                @include fs-action-kicker(5);
            }

            @include mq(desktop) {
                padding-bottom: gs-height(4);
            }
        }

        .fc-item__content {
            max-width: 100%;
        }

        .fc-item__header,
        .fc-item__footer--vertical,
        .fc-item__standfirst-wrapper {
            width: auto;
            padding-right: gs-height(5);

            @include mq(desktop) {
                padding-right: gs-height(5.5);
            }
        }

        .fc-item__standfirst {
            display: flex;
        }

        &.fc-item--has-sublinks-3 {
            .fc-item__footer--horizontal {
                flex-basis: auto;
            }

            &:not(.fc-item--type-comment) {
                .fc-item__footer--horizontal {
                    .fc-sublink {
                        margin-top: 0;

                        .fc-sublink__title {
                            &:before {
                                width: auto;
                                left: 0 - $fc-item-gutter;
                                right: 0 - $fc-item-gutter;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Tooltip Box
   Box that sits on top of the content, absolutely positioned
   Larger than a standard tooltip and allows a close icon */

.tooltip-box {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $zindex-content;
    padding: $gs-baseline / 2 $gs-gutter / 2;
}

.tooltip-box--neutral {
    color: $brightness-7;
    border: 2px solid $brightness-93;
    background: #ffffff;
}

.tooltip-box--width4 {
    width: gs-span(4);
}

.tooltip-box__close {
    float: right;
    margin-bottom: $gs-baseline / 2;
    width: auto;
}

.tooltip-box__p {
    margin-top: $gs-baseline / 2;
    text-align: left;
}

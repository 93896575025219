
// Here be SVGs - use appropriate styling properties
// http://www.w3.org/TR/SVG11/styling.html
.chart--doughnut {
    position: relative;

    .chart__label {
        @include fs-textSans(5);
        fill: #ffffff;
        text-anchor: middle;
    }

    .chart__label-value {
        @include fs-headline(6);
        font-weight: bold;
    }

    .chart__unit {
        @include fs-headline(6);
        fill: $brightness-46;
        font-weight: 900;
        text-anchor: middle;
    }
}

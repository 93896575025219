@mixin immersiveCommon() {
    .fc-item__kicker {
        color: $highlight-main;
        display: block;
    }

    .fc-item__headline {
        color: #ffffff;
    }

    .fc-item__container {
        overflow: hidden;
        position: relative;
    }

    .fc-item__standfirst,
    .fc-sublinks {
        display: none;
    }

    .fc-item__content {
        position: relative;
    }

    .fc-item__headline {
        color: #ffffff;
    }

    .fc-item__media-wrapper {
        position: static;

        img {
            object-fit: cover;
        }
    }

    // ensures image is positioned relative to fc-item__container
    .u-responsive-ratio {
        position: static;
    }
}

@mixin immersiveBottom() {
    @include immersiveCommon();

    .fc-item__content {
        margin: 0 30px 0 0;
    }

    &.fc-item--third-tablet  {
        @include mq($from: tablet) {
            .fc-item__content {
                max-width: gs-span(3);
            }
        }
    }

    &.fc-item--half-tablet  {
        @include mq($from: tablet) {
            .fc-item__content {
                max-width: gs-span(4);
            }
        }
    }

    &.fc-item--three-quarters-tall-tablet {
        @include mq($from: tablet) {
            .u-responsive-ratio {
                padding-bottom: 80%;
            }
        }
    }

    &.fc-item--standard-mobile {
        @include mq($until: tablet) {
            .u-responsive-ratio {
                padding-bottom: 60%;
            }
        }
    }
}

@mixin immersiveLeft() {
    @include immersiveCommon();

    .fc-item__content {
        margin: 0 auto 0 0;
        min-width: gs-span(5) - ($gs-gutter * 2);

        @include mq(desktop) {
            min-width: gs-span(6);
        }
    }

    .u-responsive-ratio {
        padding-bottom: 45%;
    }
}

@mixin immersiveRight() {
    @include immersiveCommon();

    .u-responsive-ratio {
        padding-bottom: 45%;
    }
}

.fc-item--type-immersive.fc-item--has-boosted-title {

    // ?# It is very easy to opt out as the below, but
    // it increases the cyclomatic complexity of all selectors.
    // Find a better solution!
    &:not(.fc-item--paid-content) {

        .fc-item__kicker:before,
        .fc-item__kicker:after {
            content: none;
        }

        &.fc-item--list-media-mobile .fc-item__kicker:after,
        &.fc-item--list-mobile .fc-item__kicker:after
        {
            content: '/';

            @include mq($from: tablet) {
                content: none;
            }
        }

        &.fc-item--list-media-tablet .fc-item__kicker:after,
        &.fc-item--list-tablet .fc-item__kicker:after
        {
            content: '/';
        }

        .fc-item__meta {
            margin-left: auto;
            color: #ffffff;
        }

        .inline-icon {
            fill: #ffffff;
        }

        &.fc-item--standard-mobile {
            @include mq($until: tablet) {
                @include immersiveBottom();
            }
        }

        &.fc-item--third-tablet,
        &.fc-item--half-tablet,
        &.fc-item--full-media-100-tablet,
        &.fc-item--three-quarters-tall-tablet {
            @include mq($from: tablet) {
                @include immersiveBottom();
            }
        }

        &.fc-item--full-media-75-tablet,
        &.fc-item--full-media-50-tablet,
        &.fc-item--three-quarters-tablet {
            @include mq($from: tablet) {
                @include immersiveLeft();
            }
        }

        &.fc-item--three-quarters-right-tablet {
            @include mq($from: tablet) {
                @include immersiveRight();
            }
        }
    }
}

.hostedbadge {
    position: relative;
    margin-left: $gs-gutter;
    width: 80px;
    z-index: $zindex-content;

    &:hover,
    &:focus {
        text-decoration: none;
    }
    @include mq(desktop) {
        width: 132px;
        .hostedbadge__info {
            text-align: center;
            margin: 2px 0;
        }
    }
}

.hostedbadge--pushdown {
    top: 0;
}

.hostedbadge__info {
    @include f-textSans;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 15px;
    height: auto;
    padding: 5px 6px 4px;
    color: $brightness-7;
    margin: 0;
    text-align: left;
}

.hostedbadge .hostedbadge__logo {
    position: absolute;
    display: block;
    width: 100%;
    top: 100%;
    background-color: #ffffff;
}

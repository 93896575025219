$f-serif-text: 'Guardian Text Egyptian Web', Georgia, serif !default;
$f-serif-headline: 'Guardian Egyptian Web', Georgia, serif !default;
$f-sans-serif-text: 'Guardian Text Sans Web', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif !default;

$font-scale: (
    header: (
        15: (font-size: 14, line-height: 17),
        2: (font-size: 17, line-height: 20),
        3: (font-size: 20, line-height: 23),
        5: (font-size: 24, line-height: 27),
    ),
    headline: (
        1: (font-size: 14, line-height: 17),
        2: (font-size: 17, line-height: 20),
        3: (font-size: 20, line-height: 23),
        4: (font-size: 24, line-height: 27),
        5: (font-size: 28, line-height: 32),
        6: (font-size: 34, line-height: 38),
        8: (font-size: 42, line-height: 46),
        9: (font-size: 50, line-height: 58),
        10: (font-size: 70, line-height: 78),
    ),
    bodyHeading: (
        1: (font-size: 14, line-height: 22),
        2: (font-size: 17, line-height: 24),
        4: (font-size: 20, line-height: 28),
    ),
    bodyCopy: (
        1: (font-size: 14, line-height: 18),
        2: (font-size: 17, line-height: 24),
    ),
    textSans: (
        1: (font-size: 12, line-height: 16),
        2: (font-size: 13, line-height: 18),
        3: (font-size: 14, line-height: 20),
        4: (font-size: 14, line-height: 22),
        5: (font-size: 16, line-height: 22),
        6: (font-size: 18, line-height: 18),
    ),
    actionKicker: (
        1: (font-size: 14),
        2: (font-size: 14),
        3: (font-size: 15),
        4: (font-size: 17),
        5: (font-size: 20),
        6: (font-size: 20),
        8: (font-size: 20),
        9: (font-size: 20),
        10: (font-size: 20),
    )
);

// Default type settings
// =============================================================================

@mixin font-size($size, $line-height: $size) {
    font-size: convert-to-px($size);
    line-height: convert-to-px($line-height);
}

@mixin font($family, $weight, $size, $line-height: $size) {
    @include font-size($size, $line-height);
    font-family: $family;
    font-weight: $weight;
}

// Font scale
// =============================================================================

@mixin f-header {
    font-family: $f-serif-headline;
    font-weight: 900;
}

@mixin fs-header($level, $size-only: false) {
    @include font-size(get-font-size(header, $level), get-line-height(header, $level));

    @if $size-only == false {
        @include f-header;
    }
}

@mixin f-headline {
    font-family: $f-serif-headline;
    font-weight: normal;
}

@mixin fs-headline($level, $size-only: false) {
    @include font-size(get-font-size(headline, $level), get-line-height(headline, $level));

    @if $size-only == false {
        @include f-headline;
    }
}

@mixin action-kicker-font-size($size) {
    font-size: get-font-size(actionKicker, $size);
    line-height: 115%;
}

@mixin f-bodyHeading {
    font-family: $f-serif-text;
    font-weight: bold;
}

@mixin fs-bodyHeading($level, $size-only: false) {
    @include font-size(get-font-size(bodyHeading, $level), get-line-height(bodyHeading, $level));

    @if $size-only == false {
        @include f-bodyHeading;
    }
}

@mixin f-bodyCopy {
    font-family: $f-serif-text;
}

@mixin fs-bodyCopy($level, $size-only: false) {
    @include font-size(get-font-size(bodyCopy, $level), get-line-height(bodyCopy, $level));

    @if $size-only == false {
        @include f-bodyCopy;
    }
}

@mixin f-textSans {
    font-family: $f-sans-serif-text;
}

@mixin fs-textSans($level, $size-only: false) {
    @include font-size(get-font-size(textSans, $level), get-line-height(textSans, $level));

    @if $size-only == false {
        @include f-textSans;
    }
}

// Font scale helpers
// =============================================================================

@function get-scale($name, $font-scale: $font-scale) {
    @return map-get($font-scale, $name);
}

@function get-scale-level($name, $level, $font-scale: $font-scale) {
    @return map-get(get-scale($name, $font-scale), $level);
}

@function get-font-size($name, $level, $font-scale: $font-scale) {
    @return convert-to-px(map-get(get-scale-level($name, $level, $font-scale), font-size));
}

@function get-line-height($name, $level, $font-scale: $font-scale) {
    @return convert-to-px(map-get(get-scale-level($name, $level, $font-scale), line-height));
}

@function convert-to-px($value) {
    @if (type-of($value) == number and $value != 0) {
        $value: if(unitless($value), $value * 1px, $value);
    }
    @return $value;
}

.match-stats {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0;
    color: $brightness-7;
}

.match-stats__heading {
    @include fs-header(3);
    font-weight: 900;
    grid-column-start: 1;
    grid-column-end: 3;

    @include mq(leftCol) {
        position: relative;
        height: 0;
        left: -160px;
        top: -4px;
        margin: 0;
    }

    @include mq(wide) {
        left: -240px;
    }
}

.match-stats__caption {
    @include fs-textSans(5);
    margin-bottom: $gs-baseline/6;
    color: $brightness-7;
    font-weight: 400;
    text-align: left;
    width: 100%;
}

.match-stats__column {
    padding-bottom: $gs-baseline;

    @include mq($until: tablet) {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    @include mq(tablet) {
        margin-right: 10px;
    }

    @include mq(leftCol) {
        padding-bottom: $gs-baseline*1.5;
    }

    &.match-stats__column--first {
        @include mq(tablet) {
            padding-right: 10px;
            border-right: 1px solid $brightness-86;
        }
    }
}

.match-stats__stat {
    margin: 0;
}

/* ==========================================================================
   Goal attempts
   ========================================================================== */

.goal-attempts {
    @include fs-textSans(5);
    color: #ffffff;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.goal-attempts__label {
    box-sizing: border-box;
    font-weight: normal;
    position: absolute;
    margin: 0;
    width: 100%;
    z-index: 2;
}

.goal-attempts__label--off-target-home {
    top: 34px;
    left: 4px;
}

.goal-attempts__label--on-target-home {
    bottom: 10px;
    left: 23.2%;
}

.goal-attempts__off-target,
.goal-attempts__on-target {
    @include fs-headline(6);
    line-height: .8;
    box-sizing: border-box;
    padding: 2px 4px;
    font-weight: 900;
    margin: 0;
    width: 50%;
}

.goal-attempts__off-target {
    height: $gs-baseline*11;
    background-image: url('data:image/svg+xml, %3Csvg%20width%3D%229%22%20height%3D%2212%22%20viewBox%3D%220%200%209%2012%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22rgba%28255%2C%20255%2C%20255%2C%200.3%29%22%3E%3Ccircle%20cx%3D%221.5%22%20cy%3D%221.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%221.5%22%20cy%3D%224.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%221.5%22%20cy%3D%227.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%221.5%22%20cy%3D%2210.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%224.5%22%20cy%3D%221.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%224.5%22%20cy%3D%224.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%224.5%22%20cy%3D%227.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%224.5%22%20cy%3D%2210.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%227.5%22%20cy%3D%221.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%227.5%22%20cy%3D%224.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%227.5%22%20cy%3D%227.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%227.5%22%20cy%3D%2210.5%22%20r%3D%221%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    background-repeat: repeat;
    background-size: 10px;
    background-position-x: 0px;
}

.goal-attempts__on-target {
    box-sizing: border-box;
    height: $gs-baseline*6;
    border: 6px solid #ffffff;
    border-bottom: 0;
    bottom: 0;
    position: absolute;
    width: 30%;
}

.goal-attempts__off-target--home,
.goal-attempts__on-target--home {
    background-color: $brightness-7;
    float: left;
}
.goal-attempts__off-target--away,
.goal-attempts__on-target--away {
    background-color: #ffffff;
    float: right;
    text-align: right;
}
.goal-attempts__on-target--home {
    border-right: 0;
    left: 20%;
}
.goal-attempts__on-target--away {
    border-left: 0;
    right: 20%;
}

/* ==========================================================================
   Bar fights
   ========================================================================== */

.bar-fight {
    .match-stats__caption {
        float: left;
        margin-top: 6px;
    }
}

.bar-fight__bar {
    @include fs-headline(6);
    line-height: .8;
    box-sizing: border-box;
    padding: 2px 6px 9px;
    background-color: #e0eff8;
    color: #ffffff;
    font-weight: bold;
    float: left;
    margin: 0;
    text-align: right;
}

.bar-fight__bar--home {
    background-color: $brightness-7;
    border-right: 2px solid #ffffff;
    text-align: left;
}

/* ==========================================================================
    Possession doughnut
   ========================================================================== */

.football-possession {
    position: relative;
    height: 0;
    padding-top: 320px;
    margin-bottom: $gs-baseline;
    text-align: center;
    width: 100%;
    max-width: 320px;
    margin: auto;

    @include mq(desktop) {
        padding-top: 290px; // width of column without 10px padding
    }
}

.chart--football-possession {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    .chart__unit {
        fill: $brightness-7;
    }
}

/* ==========================================================================
   Lineups
   ========================================================================== */

.match-stats__lineup {
    margin-right: 10px;
}

.match-stats__lineup--home {
    @include mq(tablet) {
        border-right: $brightness-86 1px solid;
        padding-right: 10px;
    }
}

.match-stats__players {
    margin-bottom: $gs-baseline;
}

.match-stats__player {
    @include fs-textSans(5);
}

.match-stats__player .i {
    transform: rotate(8deg);
}

.match-stats__player-number {
    font-weight: bold;
    width: 24px;
    display: inline-block;

    @include mq(mobileLandscape) {
        width: 30px;
    }
}

/* ==========================================================================
   Dark colour overrides
   ========================================================================== */

.match-stats--darken-home {
    .bar-fight__bar--home,
    .goal-attempts__label--off-target-home,
    .goal-attempts__label--on-target-home,
    .goal-attempts__off-target--home,
    .goal-attempts__on-target--home {
        color: $brightness-7;
    }

    .chart__arc:last-of-type .chart__label {
        fill: $brightness-7;
    }
}

.match-stats--darken-away {
    .bar-fight__bar--away,
    .goal-attempts__label--off-target-away,
    .goal-attempts__label--on-target-away,
    .goal-attempts__off-target--away,
    .goal-attempts__on-target--away {
        color: $brightness-7;
    }

    .chart__arc:first-of-type .chart__label {
        fill: $brightness-7;
    }
}

.match-stats--darken-home .goal-attempts__off-target--home,
.match-stats--darken-away .goal-attempts__off-target--away {
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%229%22%20height%3D%2212%22%20viewBox%3D%220%200%209%2012%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22rgba%280%2C%200%2C%200%2C%200.1%29%22%3E%3Ccircle%20cx%3D%221.5%22%20cy%3D%221.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%221.5%22%20cy%3D%224.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%221.5%22%20cy%3D%227.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%221.5%22%20cy%3D%2210.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%224.5%22%20cy%3D%221.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%224.5%22%20cy%3D%224.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%224.5%22%20cy%3D%227.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%224.5%22%20cy%3D%2210.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%227.5%22%20cy%3D%221.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%227.5%22%20cy%3D%224.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%227.5%22%20cy%3D%227.5%22%20r%3D%221%22%2F%3E%3Ccircle%20cx%3D%227.5%22%20cy%3D%2210.5%22%20r%3D%221%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
}

/* ==========================================================================
   Match Report overrides
   ========================================================================== */

.after-article {
    .match-stats__container {
        clear: left;
        position: relative;
        padding: 9px 0;
        background-color: mix($sport-main, #ffffff, 15%);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            z-index: -1;
            left: -10px;
            right: -10px;
            bottom: 0;
            background-color: mix($sport-main, #ffffff, 15%);

            @include mq(mobileLandscape) {
                left: -20px;
                right: -20px;
            }

            @include mq(tablet) {
                right: 0;
                left: -100vw;
            }
        }
    }

    .bar-fight__bar--home,
    .goal-attempts__on-target {
        border-color: mix($sport-main, #ffffff, 15%);
    }
}

/* ==========================================================================
   Football Stats overrides
   ========================================================================== */

.content--football-stats {
    .match-stats {
        margin-top: $gs-baseline;
    }
}

/* ==========================================================================
   Liveblog overrides
   ========================================================================== */

.content--liveblog {
    .match-stats__heading {
        height: auto;
        left: 0;
    }

    .match-stats__column {
        grid-column-start: 1;
        grid-column-end: 3;
        margin-right: 0;
    }

    .match-stats__column--first {
        padding-right: 0;
        border-right: 0;
    }
}

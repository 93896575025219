// this is here as we override styles from module/external/_from-content-api
.from-content-api {
    .element-table {
        box-sizing: border-box;
        margin-top: $gs-baseline * 2;
        margin-bottom: $gs-baseline;

        @include mq(mobileLandscape) {
            margin-top: 0;
            width: 100%;
        }

        .table td,
        .table th {
            @include fs-textSans(2, true);
        }
    }

    .element-table--complimentary {
        @include mq(mobileLandscape) {
            width: $gs-column-width * 5;
        }
    }
}

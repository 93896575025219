$player-card-avatar: (
    default: 80px,
    mobileLandscape: 100px
);


/**
 * The Markup for these classes come from the content API
 * Which in turn gets the content from our Football tool
 * You can find the templates here:
 * https://github.com/guardian/frontend/tree/main/admin/app/views/football/player/cards
 **/
.player-card {
    padding: $gs-baseline $gs-gutter/2;
    background: $brightness-97;
    border-top: 1px solid $sport-dark;
    color: $brightness-7;

    @include mq(mobileLandscape) {
        margin-top: $gs-baseline/2;
    }
}

.player-card__image-container {
    float: right;
}
.player-card__image {
    @include circular;
    height: map-get($player-card-avatar, default);
    width: map-get($player-card-avatar, default);
    float: right;

    @include mq(mobileLandscape) {
        height: map-get($player-card-avatar, mobileLandscape);
        width: map-get($player-card-avatar, mobileLandscape);
    }
}

.player-card__player {
    margin-bottom: $gs-baseline;
    min-height: map-get($player-card-avatar, default);
    padding-right: map-get($player-card-avatar, default);

    @include mq(mobileLandscape) {
        margin-bottom: $gs-baseline*2;
        min-height: map-get($player-card-avatar, mobileLandscape);
        padding-right: map-get($player-card-avatar, mobileLandscape);
    }
}
.player-card__name {
    @include fs-header(5);
    font-weight: normal;
}
.player-card__position {
    @include fs-textSans(4);
    color: $sport-dark;
}

.player-card__stats {
    margin: 0;
    padding: 0;
}
.player-card__stat-name,
.player-card__stat-value {
    margin: 0;
    padding: $gs-baseline/3 0 ($gs-baseline/3)*2;
}
.player-card__stat-name {
    @include fs-textSans(2);
    display: block;
    float: left;
    font-weight: normal;
}
.player-card__stat-value {
    @include fs-textSans(2);
    border-top: 1px solid $brightness-86;
    font-weight: bold;
    padding-left: 85%;
    text-align: right;
}

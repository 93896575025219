// Base font-sizes
// Mixin uses arguments: font(family, weight, size, line-height);

// Type extends
// =============================================================================

// DEPRECATED:
// Use font-scale mixins instead

%type-5 { @include font($f-serif-headline, normal, 18, 24); }


// Misc
// =============================================================================

%d-badge-icon {
    display: inline-block;
    position: relative;
    top: -.1em;
    width: 1em;
    height: 1em;
    margin-left: .2em;
    vertical-align: middle;
    background: $sport-dark;
    font: bold 13px/1 $f-serif-headline;
    color: #ffffff;
    text-align: center;
    cursor: help;
}

%icon-holder-circle {
    width: 30px;
    height: 30px;
    border: 1px solid $brightness-7;
    padding: 0;
    box-sizing: content-box; // Force proper sizing on button elements
    background-color: transparent;

    &,
    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
        color: $brightness-7;
    }
}
%u-unstyled {
    margin: 0;
    list-style: none;
}

%u-responsive-ratio {
    @include fix-aspect-ratio(5, 3);

    img,
    object,
    embed,
    iframe,
    svg,
    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}


.signin-gate {
    clear: left;
    margin-bottom: $gs-baseline * 2;
    @include mq(desktop) {
        min-height: 600px;
    }
}

.signin-gate__content {
    padding-top: $gs-baseline * 3;
    @include mq(tablet) {
        padding-top: $gs-baseline * 2;
    }
}

.signin-gate__header {
    @include fs-header(3);
    padding-bottom: $gs-baseline;
    @include mq(tablet) {
        padding-bottom: $gs-baseline * 2;
    }
}

.signin-gate__buttons {
    @include fs-textSans(5);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 6px;
    padding-bottom: 6px;
    // Horizontal alignment
    align-items: flex-start;

    @include mq(424px) {
        flex-direction: row;

        // Now vertical alignment, because the flex-direction is row
        align-items: center;

        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.signin-gate__padding-left {
    padding-left: $gs-baseline * 1.5;
}

.signin-gate__padding-bottom {
    padding-bottom: $gs-baseline * 1.5;
}

.signin-gate__padding-right {
    padding-right: $gs-baseline * 1.5;
}

.signin-gate__link {
    @include fs-textSans(5);
    color: $brightness-7 !important;
    text-decoration: underline;
    font-weight: 700;
    font-size: 17px;
    padding-top: 20px;
    &-no-ptm {
        padding-top: 0px;
    }
    @include mq(424px) {
        padding-top: 0px;
    }
}

.signin-gate__center-424 {
    align-self: center;
    @include mq(424px) {
        align-self: auto;
    }
}

.signin-gate__dismiss {
    @include fs-textSans(5);
    background: 0;
    border: 0;
    color: $brightness-7 !important;
    text-decoration: underline;
    font-weight: 700;
    font-size: 17px;
    align-self: center;
    margin-left: $gs-baseline * 1.5;
    @include mq(424px) {
        align-self: auto;
        margin-left: $gs-baseline * 2
    }
}

.signin-gate__first-paragraph-container {
    position: relative;
    overflow: hidden;
    .signin-gate__first-paragraph-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        /* "transparent" only works here because == rgba(0,0,0,0) */
        background-image: linear-gradient(0deg, $brightness-100, 10%, rgba(255, 255, 255, 0));

        &.signin-gate__first-paragraph-overlay--comment {
            background-image: linear-gradient(0deg, $opinion-faded, 10%, rgba(255, 255, 255, 0));
        }
    }
}

.signin-gate__button {
    @include fs-textSans(5);
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-weight: 700;
    font-size: 17px;
    height: 42px;
    min-height: 42px;
    padding: $gs-baseline;
    border: 0;
    border-radius: 21px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0);
    cursor: pointer;
    transition: .3s ease-in-out;;
    justify-content: space-between;
    position: relative;
    white-space: nowrap;

    &:hover {
        outline: 0;
    }

    &[data-disabled] {
        pointer-events: none;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    align-self: center;
    @include mq(424px) {
        align-self: auto;
        padding: $gs-baseline $gs-baseline * 2;
    }
}

.signin-gate__button--primary {
    background-color: $brand-main;
    color: $brightness-100 !important;
    &:hover, &:focus {
        background-color: $brand-dark;
    }
}

.signin-gate__header--text {
    font-weight: normal;
    line-height: 1.25em;
    font-size: 24px;
    @include mq(tablet) {
        font-size: 32px;
    }
}

.signin-gate__paragraph {
    border-top: 1px solid $brightness-46;
    margin-bottom: $gs-baseline * 2;
    padding-top: $gs-baseline;
}

.signin-gate__paragraph--text {
    font-size: 16px;
    @include mq(tablet) {
        font-size: 18px;
    }
}

.signin-gate__benefits {
    border-top: 1px solid $brightness-46;
    padding-bottom: $gs-baseline;
    @include mq(tablet) {
        padding-top: $gs-baseline;
    }
}

.signin-gate__benefits--text {
    padding-top: $gs-baseline / 3;
    font-weight: bold;
    color: $sport-main;
    font-size: 17px;
    @include mq(tablet) {
        font-size: 20px;
    }
    &-news {
        color: $news-main;
    }
    &-comment {
        color: $opinion-main
    }
    &-sport {
        color: $sport-main
    }
    &-culture {
        color: $culture-main
    }
    &-lifestyle {
        color: $lifestyle-main
    }
}

// VARIANT DESIGN - vii-variant //

.signin-gate__header--var {
    @include fs-header(3);
    border-top: 1px solid $brightness-7;
    @include mq(tablet) {
        border-top: 2px solid $brightness-7;
    }
}

.signin-gate__benefits--var {
    border-top: 1px solid $brightness-86;
    padding-bottom: $gs-baseline;
}

.signin-gate__benefits--text--var {
    font-family: $f-sans-serif-text;
    font-size: 20px;
    font-weight: bold;
    color: $brightness-7;
    padding-top: $gs-baseline / 3;
    @include mq(tablet) {
        font-size: 20px;
    }
}

.signin-gate__margin-top--var {
    margin-top: $gs-baseline * 3;
    @include mq(tablet) {
        margin-top: $gs-baseline * 3;
    }
}

.signin-gate__link--var {
    @include fs-textSans(5);
    color: $brightness-7 !important;
    text-decoration: underline;
    font-size: 17px;
    padding-top: 10px;
    &-no-ptm {
        padding-top: 0px;
    }
    @include mq(424px) {
        padding-top: 0px;
    }
}

.signin-gate__dismiss--var {
    @include fs-textSans(5);
    color: $brand-main !important;
    background: 0;
    border: 0;
    font-weight: 700;
    font-size: 17px;
    align-self: center;
    margin-left: $gs-baseline * 1.5;
    @include mq(424px) {
        align-self: auto;
        margin-left: $gs-baseline * 2
    }
}

.signin-gate__signin--var {
    color: $sport-main !important;
}

.signin-gate__paragraph--text--var {
    font-family: $f-sans-serif-text;
    font-size: 17px;
    margin-bottom: $gs-baseline * 2;
    @include mq(tablet) {
        font-size: 1h7px;
    }
}

.signin-gate__header--text--var {
    font-weight: bold;
    line-height: 1.25em;
    font-size: 28px;
    @include mq(tablet) {
        font-size: 32px;
    }
}

.signin-gate__button--primary--var {
    background-color: $brand-main;
    color: $brightness-100 !important;
    padding: 0 20px;
    &:hover, &:focus {
        background-color: $brand-dark;
    }
}

.signin-gate__paragraph--var {
    margin-bottom: $gs-baseline * 2;
}

.signin-gate__faqlinks--var {
    padding-bottom: $gs-baseline * 3;
    padding-top: $gs-baseline * 1;
    position: relative;
}
.signin-gate__faqlinks--var--comment {
    padding-bottom: $gs-baseline * 3;
    padding-top: $gs-baseline * 1;
    position: relative;
}

.overlay--var {
    height: 70% !important;
    background-image: linear-gradient(to top, $brightness-100, $brightness-100 40%, rgba(255, 255, 255, 0)) !important;
}

.overlay--comment--var {
    height: 70% !important;;
    background-image: linear-gradient(to top, $opinion-faded , $opinion-faded 40%, rgba(255, 255, 255, 0)) !important;
}



.footer__email-container {
    grid-area: signup;
    border-bottom: 1px solid $footer-line-colour;
    padding-top: 8px;
    padding-bottom: 12px;

    @include mq(leftCol) {
        float: left;
        padding-top: $gs-baseline / 4;
        border: 0;
        width: gs-span(4);
    }

    @include mq(wide) {
        width: gs-span(6);
    }

    .button {
        border-color: $brightness-46;

        &:hover {
            border-color: $brightness-86;
        }

        @include mq(tablet) {
            display: inline-block;
            margin-bottom: $gs-baseline;
            width: auto;
        }
    }
}

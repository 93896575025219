
/* ==========================================================================
   Tabs
   ========================================================================== */

/* Default tabs
   ========================================================================== */
.tabs__container {
    position: relative;
    margin: 0;
    padding: 0;
    border-bottom: 0;
    list-style-type: none;
    overflow: hidden;
    z-index: 2;
    width: 100%;
    background: lighten($brightness-93, 5%);
    table-layout: fixed;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    grid-column-gap: 2px;
    display: table;
    display: grid;

    @include mq(desktop) {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }

    @include mq(tablet) {
        margin-bottom: -1px;
    }
}

.tabs__tab {
    margin: 0;
    border-bottom: 1px solid $brightness-86;
    border-top: 3px solid $brightness-93;
    float: left;
    width: 50%;

    @supports ( display: grid ) {
        width: 100%;
    }

    @include mq(tablet) {
        display: table-cell;
        float: none;
        width: 100%;
    }


    a,
    .tab__link {
        @include fs-headline(2);
        font-weight: 600;
        display: block;
        box-sizing: border-box;
        min-height: $gs-row-height;
        padding: $gs-baseline/3 $gs-gutter/3.5 0;
        text-align: left;
        text-decoration: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: $brightness-93;

        @include mq($until: tablet) {
            font-size: 14px;
        }
    }

    a {
        color: inherit;
    }

    & a:active,
    & a:focus,
    & .tab__link:active,
    & .tab__link:focus,
    &.tabs__tab--selected a,
    &.tabs__tab--selected .tab__link {
        background-color: #ffffff;
        text-decoration: none;
    }

}

.tabs__content {
    position: relative;
    clear: both;
    padding-top: 0;
    z-index: 1;

    @include mq(tablet) {
        border-top: 1px solid $brightness-86;
    }
}

.tabs__content--no-border {
    border-top: 0;
}

.tabs__heading {
    display: none;
}

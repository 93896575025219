.search-tool__input {
    @include ellipsis();
    display: block;
    position: relative;
    z-index: 4;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid transparent; // To allow for focus state
    padding: 0;

    &:focus {
        outline: 0;
    }

    .is-editing & {
        text-overflow: clip;
    }
}

.search-tool__btn {
    background-color: $brand-main;
    border: 0;
    width: 28px;
    height: 28px;
}

.search-tool__list {
    position: absolute;
    z-index: 5;
    background-color: #ffffff;
    width: 100%;
}

.search-tool__item {
    border-top: 1px solid $brightness-86;

    &:first-of-type {
        border-top: 0;
    }
}

.search-tool__link {
    @include fs-headline(2);
    box-sizing: border-box;
    color: $brightness-7;
    padding-top: $gs-baseline / 3;
    padding-bottom: $gs-baseline;
    width: 100%;
    display: block;

    @include mq(tablet, leftCol) {
        padding-left: $gs-gutter / 4;
    }

    .has-page-skin & {
        @include mq(wide) {
            padding-left: $gs-gutter / 4;
        }
    }

    &:hover,
    &.active {
        background-color: $brightness-86;
        text-decoration: none;
    }
}

.search-tool__meta {
    @include fs-textSans(2);
    color: $brightness-46;
    display: block;
    line-height: 16px;
}

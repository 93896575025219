@mixin overide-live-blog-colours($pillar, $color1, $color2) {
    //when live blog is tagged match report
    .fc-item--live.fc-item--type-matchreport.fc-item--pillar-#{$pillar},
    .fc-item--type-live.fc-item--pillar-#{$pillar} {
        background-color: $color1;

        .fc-item__container::before {
            background-color: $color2;
        }

        .live-pulse-icon::before {
            background-color: $color2;
        }

        .fc-item__kicker {
            color: $color2;
        }

        .fc-item__headline {
            @if $pillar == special-report-alt {
                color: $color2;
            } @else {
                color: #ffffff;
            }
        }

        .fc-item__standfirst {
            @if $pillar == special-report-alt {
                color: $color2;
            } @else {
                color: #ffffff;
            }
        }

        .fc-item__meta {
            color: $color2;

            .inline-icon {
                fill: $color2;
            }
        }

        .fc-sublink__title {
            &::before {
                border-top-color: $color2;
            }
        }

        .fc-sublink__link {
            @if $pillar == special-report-alt {
                color: $color2;
            } @else {
                color: #ffffff;
            }
        }

        .fc-sublink__kicker {
            color: $color2;
        }

        // darken on hover
        .u-faux-block-link--hover {
            @if $pillar == special-report-alt {
                background-color: darken($special-report-alt-pastel, 5%);
            } @else {
                background-color: darken($color1, 5%);
            }
        }

        // list media overrides
        &.fc-item--list-media-mobile {
            @include mq($from: mobile, $until: tablet) {
                .fc-sublink__title {
                    &::before {
                        border-top-color: $color2;
                    }
                }
            }
        }

        &.fc-item--list-media-tablet {
            @include mq($from: tablet) {
                .fc-sublink__title {
                    &::before {
                        border-top-color: $color2;
                    }
                }
            }
        }
    }
}

@include overide-live-blog-colours(news, $news-dark, $news-pastel);
@include overide-live-blog-colours(sport, $sport-dark, $sport-pastel);
@include overide-live-blog-colours(arts, $culture-dark, $culture-pastel);
@include overide-live-blog-colours(lifestyle, $lifestyle-dark, $lifestyle-pastel);
@include overide-live-blog-colours(special-report-alt, $special-report-alt-faded, $special-report-alt-dark);

.submeta {
    @include multiline(4, $brightness-86, top);
    margin-top: $gs-baseline;
    padding-top: $gs-baseline * 1.5;

    .content--media:not(.paid-content):not(.content--gallery) & {
        @include multiline(4, $brightness-20, top);

        .submeta__keywords {
            border-color: $brightness-20;
        }
    }

    .paid-content & {
        @include multiline(4, $brightness-60, top);

        .submeta__keywords {
            border-color: $brightness-60;
        }
    }
}

.submeta--borderless-bottom .submeta__keywords {
    border-bottom: 0;
}

.submeta__label {
    @include fs-textSans(1);
    color: $brightness-60;
    display: block;
    margin-top: -($gs-baseline / 4);
}

.submeta__keywords {
    border-bottom: 1px solid $brightness-86;
    margin-bottom: $gs-baseline / 2;
    padding-bottom: $gs-baseline;
}

// Nesting 'article' overrides from-content-api ul rules
article .submeta__links,
.submeta__links {
    font-size: 0;
    margin: 0 0 0 (-$gs-gutter / 4);
}

.submeta__link-item {
    @include trailing-slash-item;
}

.submeta__link {
    @include trailing-slash-link;
    color: $brightness-7;

    .submeta__keywords & {
        @include fs-textSans(4);
    }

    .submeta__section-labels & {
        @include fs-headline(2);
    }

    &:after {
        @include trailing-slash;
        color: $brightness-86;
    }
}

.submeta__badge {
    float: right;
    margin-top: -$gs-baseline;
    margin-left: $gs-gutter / 2;
}

.submeta__share {
    float: left;
    margin-bottom: $gs-baseline / 2;
}

.submeta__syndication {
    display: none;

    @include mq(desktop) {
        @include fs-textSans(1);
        padding-top: $gs-baseline / 2;
        display: inline-block;
        float: right;
    }
}

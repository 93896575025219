@mixin overide-interview-headline-colours($pillar, $color1, $color2) {
    .fc-item--type-interview.fc-item--pillar-#{$pillar} {
        .fc-item__headline {
            color: $color1;
        }
    }
}

@include overide-interview-headline-colours(news, $news-dark, $news-pastel);
@include overide-interview-headline-colours(sport, $sport-dark, $sport-pastel);
@include overide-interview-headline-colours(arts, $culture-dark, $culture-pastel);
@include overide-interview-headline-colours(lifestyle, $lifestyle-dark, $news-pastel);
@include overide-interview-headline-colours(special-report, #ffffff, #ffffff);
@include overide-interview-headline-colours(special-report-alt, $special-report-alt-dark, $special-report-alt-dark);

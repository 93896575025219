// Fix a bug Internet Explorer 8 where text in password fields
// would become invisible when `font-family` references a webfont.
[type=password] {
    font-family: sans-serif !important;
}

.form {
    margin-top: $gs-baseline*2;
    margin-bottom: $gs-baseline*2;
}

.form__heading {
    @include fs-bodyHeading(2);
    margin-left: 0;
    margin-right: 0;
}

.form__note,
.form-field__note {
    font-family: $f-sans-serif-text;
    font-size: 14px;
    margin-bottom: 8px;
}

.fieldset {
    border: 0;
    border-top: 1px solid $brightness-97;
    display: table;
    padding: (($gs-baseline/3)*4 - 1) 0 ($gs-baseline*2);
    margin: 0;
}

@include mq(desktop) {
    .fieldset__heading {
        display: table-cell;
        padding-right: $gs-gutter*5;
        width: gs-span(3);
        vertical-align: top;
    }

    .fieldset__fields {
        display: table-cell;
        vertical-align: top;
        width: gs-span(6);
        max-width: gs-span(6);
        & ul {
            margin-left: 0;
        }
    }
}

.form-fields-group .form-field {
    margin-bottom: 0;
}

.form-field {
    list-style: none;
    margin: 0 0 $gs-baseline*2;
    padding: 0;
}

.form-field__submit {
    .form-field__note {
        margin: 0 0 $gs-baseline*2;

        @include mq(desktop) {
            float: right;
            width: 60%;
            margin: 0;
        }
    }
}

.form-field--no-margin {
    margin: 0;
}

.form-field--error {
    .label {
        color: $news-main;
    }

    .text-input,
    .text-input:focus {
        border-color: $news-main;
    }
}

.form__error {
    @include fs-textSans(2);
    background-color: lighten($news-main, 55%);
    border-bottom: 1px solid lighten($news-main, 35%);
    border-top: 1px solid lighten($news-main, 35%);
    color: $news-main;
    margin-top: 6px;
    padding: 7px ($gs-baseline/3)*2;
}

.form__success {
    @include fs-textSans(2);
    background-color: lighten($green-bold, 70%);
    border-bottom: 1px solid lighten($green-bold, 35%);
    border-top: 1px solid lighten($green-bold, 35%);
    color: $green-bold;
    margin-top: $gs-baseline/2;
    padding: 7px ($gs-baseline/3)*2;
}

.form-field__error {
    color: $news-main;
    margin-top: $gs-baseline/2;
}

.form-field__note--below {
    margin-bottom: 0;
    margin-top: $gs-baseline/2;
}

.form-field__note--left {
    float: left;
}

.form-field__note--right {
    float: right;
    margin-left: $gs-gutter;
}

.form-fields__inline {
    ul {
        margin: 0;
        padding: 0;

        .form-field {
            display: inline-block;
        }
    }

    .form-fields__heading {
        @include fs-bodyHeading(2, true);
        margin: 0 0 $gs-baseline;
        display: block;
    }
}

.form-fields--date-of-birth .form-field {
    margin: 0 18px 0 0;
}

.select--telephone-number-country-code {
    width: 100%;
}

.label {
    cursor: pointer;
    display: block;
    margin-bottom: 6px;
}

.text-input,
.textarea {
    border: 1px solid $brightness-86;
    box-shadow: none;
    box-sizing: border-box;
    color: $brightness-7;
    display: inline-block;
    font-family: $f-sans-serif-text;
    padding: 8px 8px 7px;
    font-size: 16px;
    line-height: 1.4;
    outline: none;
    border-radius: 0;
    width: 100%;
    -webkit-appearance: none;

    &:focus {
        border-color: $brightness-46;
    }

    @include mq(tablet) {
        font-size: 14px;
    }
}

.textarea {
    resize: vertical;
}

.textarea--no-resize {
    min-height: ($gs-baseline/3)*20;
    resize: none;
}

.textarea--mid {
    min-height: $gs-baseline*9;
}

.textarea--long {
    min-height: ($gs-baseline/3)*40;
}

.submit-input, .submit-input--behaviour {
    background: $sport-dark;
    border: 0 none;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    margin: 0 $gs-gutter 0 0;
    min-width: gs-span(2);
    outline: none;
    text-align: center;

    &:hover,
    &:focus {
        background: darken($sport-dark, 5%);
    }

    &:active {
        background: $brightness-7;
    }
}

.submit-input {
    padding: 11px $gs-gutter/2;
}

.submit-input[disabled] {
    background: $brightness-86;
}

.check-label,
.radio-label {
    display: block;
    font-family: $f-sans-serif-text;
    font-size: 14px;
    margin-bottom: $gs-baseline/3;
    padding-left: $gs-gutter;
}

.check-label--helper {
    display: inline-block;
    vertical-align: middle;
}

[type=checkbox],
[type=radio] {
    float: left;
    height: 13px;
    margin-left: -$gs-gutter;
    margin-top: 2px;
    width: 13px;
}

.delete-input-warn {
    background-color: $news-dark;
    &:hover,
    &:focus {
        background-color: darken($news-dark, 5%);
    }
}

.delete-input-warn[disabled] {
    background-color: lighten($news-dark, 5%);
    opacity: .6;
    pointer-events: none;
}

.survey-close {
    position: absolute;
    cursor: pointer;
    top: 4px;
    right: 8px;

    svg {
        margin-top: 5px;
        width: 20px;
        height: 20px;
    }
}

.survey-overlay-simple {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .8);
    z-index: $zindex-overlay;
    width: 100%;
    height: 100%;


    .site-message__close-btn {
        border-color: #333333;
        width: 42px;
        height: 42px;

        @include mq($until: tablet) {
            position: static;
        }

        .inline-icon {
            fill: #333333;
        }
    }

    .survey-container {
        @include f-textSans;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 7.5%;
        right: 7.5%;
        max-height: 90%;
        background-color: #ffffff;
        box-sizing: border-box;
        overflow-y: scroll;

        @include mq(tablet) {
            left: 50%;
            top: percentage(1/3);
            width: $gs-column-width * 10;
            height: auto;
            margin-left: -$gs-column-width * 5;
            margin-top: 40px;
            overflow-y: auto;
        }
    }

    .survey-logo {
        position: absolute;
        bottom: 25px;
        right: 20px;

        svg {
            fill: #333333;
            width: 26px;
        }
    }

    .survey-icon {
        text-align: center;
        padding-top: 40px;
        background-color: #e3e3e3;
        line-height: 10px;

        svg {
            width: 240px;
            height: 154px;
            @include mq(tablet) {
                width: 350px;
                height: 226px;
            }
        }
    }

    .survey-text__header {
        @include fs-textSans(4);
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 0;
        padding: 6px 0 0 10px;
        background-color: #f8b600;
        height: 44px;
    }

    .survey-text__strong {
        @include fs-textSans(4);
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
        padding: 6px 0 0 10px;
        background-color: #f8b600;
        height: 44px;
    }

    .survey-text {
        padding: 4px 10px 0;
    }
}

// Default

.block-share__item {
    display: block;
    float: left;
    cursor: pointer;
    border: 1px solid $brightness-86;
    margin-right: $gs-gutter / 4;
    width: $gs-baseline * 2;
    height: $gs-baseline * 2;
    box-sizing: border-box;

    .inline-icon svg {
        width: 23px;
        height: 23px;
    }

    @if $old-ie {
        display: none;
    }
}

.block-share__item--facebook {
    border-color: $social-facebook;
    color: $social-facebook;
    .inline-icon {
        fill: $social-facebook;
    }

    &:hover {
        border-color: darken($social-facebook, 15%);
        .inline-icon {
            fill: darken($social-facebook, 15%);
        }
    }
}

.block-share__item--twitter {
    border-color: $social-twitter;
    color: $social-twitter;
    .inline-icon {
        fill: $social-twitter;
    }

    &:hover {
        border-color: darken($social-twitter, 15%);
        .inline-icon {
            fill: darken($social-twitter, 15%);
        }
    }
}

.block-share__item--linkedin {
    border-color: $social-linkedin;
    color: $social-linkedin;
    .inline-icon {
        fill: $social-linkedin;
    }

    &:hover {
        border-color: darken($social-linkedin, 15%);
        .inline-icon {
            fill: darken($social-linkedin, 15%);
        }
    }
}

.block-share--liveblog-mobile {
    margin-left: $gs-gutter / 2;
    margin-top: $gs-baseline / 2;
    cursor: pointer;
    clear: both;
}

// Live blogs

.block-share--liveblog {
    margin-left: gs-span(1) + $gs-gutter;
    margin-top: $gs-baseline;
    clear: both;
}

// Gallery

.content--media:not(.paid-content) {
    .block-share--gallery {
        position: static;

        .block-share__item {
            position: relative;
            background-color: transparent;
            border: 1px solid $brightness-46;
            box-sizing: border-box;
            transition: border-color .15s ease-out;
            width: 32px;
            height: 32px;

            &:hover {
                background-color: transparent;
                border-color: $brightness-93;
            }

            .inline-icon {
                fill: $brightness-86;

                // Directly referencing the sizes in _social.scss
                svg {
                    height: 88%;
                    width: 88%;
                    vertical-align: middle;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: auto;
                }
            }
        }
    }
}

.block-share--gallery-mobile {
    cursor: pointer;
    opacity: .8;
    margin-top: $gs-baseline / 2;
}

// Inline embeds

.content--article {

    .block-share--article,
    .block-share--article-mobile {
        position: absolute;
        right: -$gs-gutter/4;
        margin-top: ($gs-baseline/3)*2;
    }

    .element--thumbnail {
        .block-share--article {
            display: none !important;
        }
    }

    .fig--no-caption.fig--has-shares {
        padding-bottom: $gs-baseline * 3 !important;
    }

    .fig--border {
        @include mq(leftCol) {
            border-bottom: 1px dotted $brightness-86 !important;
        }

        &.element--thumbnail {
            border: 0 !important;
        }
    }

    .fig--narrow-caption {
        figcaption {
            @include mq(tablet) {
                max-width: gs-span(7) - 20;
                min-height: $gs-baseline * 2;
            }
        }

        &.element--supporting {
            figcaption {
                @include mq(tablet) {
                    max-width: gs-span(3) - 10;
                }
                @include mq(wide) {
                    max-width: gs-span(4) - 10;
                }
            }
        }

        &.element--showcase {
            figcaption {
                @include mq(leftCol) {
                    max-width: gs-span(9) - 20;
                }
            }
        }
    }

}

/*

Compact list item. Looks a bit like this:

x x x x x x x x x x x x x x x x x x x x x x x

 */

@mixin fc-item--list-compact {
    @include fs-bodyHeading(2);
    color: $brightness-7;
    padding-top: $gs-baseline/3;
    padding-bottom: $gs-baseline;
    border-top: 1px solid $brightness-93;
    display: block !important;
    background-color: transparent;

    .fc-item__container {
        @include fs-headline(2, true);
        display: block !important;
    }
}

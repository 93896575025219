.element-pullquote {
    .inline-quote {
        margin-bottom: $gs-baseline / 2;
        display: block;
        width: 34px;
        height: 30px;
    }
    svg {
        width: 34px;
        height: 30px;
    }
}

.meta__numbers {
    @include mq($from: leftCol, $until: wide) {
        // Excude templates that have a full width meta
        .content:not(.content--liveblog):not(.content--gallery):not(.content--interactive) .meta__extras:not(.meta__extras--crossword) & {
            border-top: 1px solid $brightness-86;
            margin-top: -1px;
            flex-grow: 1;
        }
    }

    .commentcount2 {
        display: block;
    }

    .commentcount2__heading,
    .sharecount__heading {
        height: 15px;
        margin: 0;
        position: relative;

        .inline-icon {
            position: absolute;
            top: 0;
            right: 0;
        }

        @include mq(leftCol) {
            padding-left: $gs-gutter * .75;
            padding-right: 0;

            .inline-icon {
                left: 0;
            }
        }
    }

    .sharecount__heading .inline-icon {
        margin-top: -3px;
    }

    .commentcount2__heading .inline-icon {
        margin-top: 1px;
        margin-right: -2px;

        svg {
            width: 18px;
            height: 18px
        }
    }

    .commentcount2__value,
    .sharecount__value {
        @include fs-textSans(6);
        font-weight: bold;
        letter-spacing: -1px;
        padding-top: $gs-baseline/5;
        display: block;
    }

    .sharecount__value {
        color: $brightness-46;
    }

    .sharecount__value--short {
        display: none;
    }
    @include mq(leftCol, wide) {
        .sharecount__value--full {
            display: none;
        }
        .sharecount__value--short {
            display: block;
        }
    }

    .sharecount__heading {
        color: $brightness-46;
    }

    .inline-share {
        fill: $brightness-46;
    }
}

.meta__number {
    display: block;
    text-align: right;
    float: left;
    min-width: 15px; // Min width is the width of the icons
    padding-top: $gs-baseline / 2;
}

.meta__number + .meta__number {
    border-left: 1px solid $brightness-86;
    padding-left: $gs-gutter / 4;
    margin-left: $gs-gutter / 4;
}

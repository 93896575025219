
.campaign--snippet {
    border-top: 1px $brightness-86 solid;
    border-bottom: 1px $brightness-86 solid;
    position: relative;
    padding: 0;
    margin: 16px -10px 36px;
    @include mq(phablet) {
        margin: 16px 0 36px -10px;
    }

    &:not([open]) .is-on,
    &[open] .is-off {
        display: none;
    }

    &[open] {
        background: $brightness-93;
    }

    p {
        font-size: 14px;
        line-height: 18px;
        font-family: $f-serif-headline;
        font-weight: 400;
        padding-top: 2px;
    }
}

.campaign--snippet .form_submit button {
    color: $brightness-7;
    background-color: $highlight-main;
    &:hover, :active {
        background-color: darken($highlight-main, 5%);
    }

    .content--pillar-news & {
        color: $brightness-100;
        background-color: $news-main;
        &:hover, :active {
            background-color: darken($news-main, 5%);
        }
    }

    .content--pillar-sport & {
        color: $brightness-100;
        background-color: $sport-dark;
        &:hover, :active {
            background-color: darken($sport-dark, 5%);
        }
    }

    .content--pillar-opinion & {
        color: $brightness-100;
        background-color: $opinion-dark;
        &:hover, :active {
            background-color: darken($opinion-dark, 5%);
        }
    }

    .content--pillar-arts & {
        color: $brightness-100;
        background-color: $culture-dark;
        &:hover, :active {
            background-color: darken($culture-dark, 5%);
        }
    }

    .content--pillar-lifestyle & {
        color: $brightness-100;
        background-color: $lifestyle-main;
        &:hover, :active {
            background-color: darken($lifestyle-main, 5%);
        }
    }
}

.campaign--snippet__handle {
    color: $brightness-100;
    background-color: $brightness-7;

    &:hover, :active {
        color: $brightness-7;
        background-color: $highlight-main;
        .icon {
            fill: $brightness-7;
        }
    }

    .content--pillar-news & {
        &:hover, :active {
            color: $brightness-100;
            background-color: $news-main;
            .icon {
                fill: $brightness-100;
            }
        }
    }

    .content--pillar-sport & {
        &:hover, :active {
            color: $brightness-100;
            background-color: $sport-dark;
            .icon {
                fill: $brightness-100;
            }
        }
    }

    .content--pillar-opinion & {
        &:hover, :active {
            color: $brightness-100;
            background-color: $opinion-dark;
            .icon {
                fill: $brightness-100;
            }
        }
    }

    .content--pillar-arts & {
        &:hover, :active {
            color: $brightness-100;
            background-color: $culture-dark;
            .icon {
                fill: $brightness-100;
            }
        }
    }

    .content--pillar-lifestyle & {
        &:hover, :active {
            color: $brightness-100;
            background-color: $lifestyle-main;
            .icon {
                fill: $brightness-100;
            }
        }
    }
}


/* SUMMARY */

.campaign--snippet > summary {
    outline: none;
    padding: 0;
    list-style: none;
    margin: 0 0 6px;

    @include mq(mobileMedium) {
        margin: 0 0 10px;
    }

    &::-webkit-details-marker {
        display: none;
    }

    .campaign--kicker {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 70px;


        @include mq(mobileMedium) {
            flex-direction: row;
        }

        > .campaign--snippet__heading-logo {
            @include fs-headline(2);
            flex: initial;
            margin-right: 10px;

            .speech-bubble {
                color: $brightness-7;
                background-color: $highlight-main;
                padding: 6px 10px 12px;
                line-height: 18px;
                min-width: 88px;
                position: relative;

                &::after {
                    content: '';
                    width: 20px;
                    height: 22px;
                    border-radius: 0 0 18px;
                    position: absolute;
                    bottom: -12px;
                    left: 10px;
                    color: $brightness-7;
                    background-color: $highlight-main;
                }

                .content--pillar-news & {
                    color: $brightness-100;
                    background-color: $news-main;
                    &::after {
                        color: $brightness-100;
                        background-color: $news-main;
                    }
                }

                .content--pillar-sport & {
                    color: $brightness-100;
                    background-color: $sport-dark;
                    &::after {
                        color: $brightness-100;
                        background-color: $sport-dark;
                    }
                }

                .content--pillar-opinion & {
                    color: $brightness-100;
                    background-color: $opinion-dark;
                    &::after {
                        background-color: $opinion-dark;
                    }
                }

                .content--pillar-arts & {
                    color: $brightness-100;
                    background-color: $culture-dark;
                    &::after {
                        background-color: $culture-dark;
                    }
                }

                .content--pillar-lifestyle & {
                    color: $brightness-100;
                    background-color: $lifestyle-main;
                    &::after {
                        background-color: $lifestyle-main;
                    }
                }
            }
        }

        > .campaign--snippet__heading-text {
            padding: 12px 10px 6px;

            @include mq(mobileMedium) {
                padding: 5px 0 0;
            }

            .campaign--snippet__headline {
                @include fs-headline(2);
                font-weight: 600;
            }
            p {
                padding-right: 5px;
            }
        }
    }
}


.campaign--snippet__handle {
    font-family: $f-sans-serif-text;
    font-size: 13px;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    transform: translate(0, 50%);
    padding: 3px 15px 0 6px;
    margin-left: 5px;
    span {
        display: inline-flex;
        align-items: center;
    }
    .icon {
        fill: $brightness-100;
        width: 16px;
        height: 16px;
        margin: -3px 6px 0 4px;
    }
}

.campaign__button--rounded {
    border-radius: 100em;
}
.campaign__button--large {
    height: 2.25em;
}

.campaign__button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border: 0;
    margin: 0 0 0 10px;

    svg:not(:root) {
        overflow: hidden;
    }
}

.content--pillar-news .campaign--snippet__handle:focus {
    background-color: $news-main;
}



/*---------- SUCCESS STATE TOGGLE -----------------*/

.success.element-campaign .campaign--snippet {
    .campaign--snippet__heading-text {
        display: none;
    }
    button {
        display: none;
    }
    form {
        display: none;
    }
    div.success_box {
        display: block;
        color: $brightness-7;
        padding: 8px 0px 10px 10px;
        p {
            font-weight: 700;
            font-family: $f-sans-serif-text;
        }
    }
}

.element-campaign .campaign--snippet {
    div.success_box {
        display: none;
    }
}

/*---------- FORM -----------------*/

.campaign--snippet form {
    display: flex;
    flex-direction: column;

    [type=radio], [type=checkbox] {
        margin-left: 0;
    }
    .error_box {
        padding: 0px 0px 10px 23px;
        color: $news-dark;
        margin-top: -35px;
        margin-bottom: 20px;
        p {
            font-family: $f-sans-serif-text;
        }
    }
}

.campaign--snippet .form_input {

    &:not(:first-child) {
        margin: 0 0 20px;
    }
    &.form_input--radio .form_field,
    &.form_input--checkbox .form_field {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        label {
            flex: initial;
            margin-right: 1em;
        }
        input {
            flex: initial;
            margin: 2px 4px 0 13px;
            min-width: 16px;
            @include mq(tablet) {
                margin: 3px 4px 0 13px;
            }
            @include mq(leftCol) {
                margin: 5px 4px 0 13px;
            }
        }
    }

    &.form_input--twitter-handle {
        position: absolute;
        left: -1000px;
    }

    textarea {
        max-width: 100%;
        min-height: 80px;
    }

    .form_option_container {
        display: flex;
        label {
            flex: initial;
            margin: 2px 0 0 4px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            font-family: $f-serif-headline;
        }
    }
}

.campaign--snippet .form_field {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $brightness-86;

    input {
        align-self: stretch;
        padding: 10px 0 10px 4px;
    }

    input,
    select,
    textarea {
        border: 1px solid var(--c-brightness-93);
        font-family: $f-sans-serif-text;
        font-size: 14px;
        line-height: 20px;
        margin: 0 10px;
    }
    select {
        height: 40px;
    }

    .form-info-text {
        margin: 1px 0 6px 3px;
        padding: 0 10px;
        line-height: 15px;
        font-size: 12px;
        font-weight: 400;
        font-family: $f-sans-serif-text;
    }

    .form_field_label {
        margin: 1px 0 6px 3px;
        padding: 0 10px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        font-family: $f-serif-headline;

        &.required::after {
            content: '*';
        }

        span {
            font-weight: 200;
            margin-right: 10px;
        }
    }
}

.campaign--snippet .form_footer {
    display: flex;
    flex-direction: row;
    border-top: 1px $brightness-86 solid;

    .form_submit {
        flex: auto;
        padding: 4px 0 40px 10px;
        .button {
            height: 30px;
            line-height: 30px;
            padding: 1px 16px;
            border: 0;
            &:disabled {
                background-color: transparent;
                border: 1px $brightness-7 solid;

            }
        }
    }
    .t_and_c {
        flex: auto;
        text-align: right;
        display: flex;
        font-family: $f-sans-serif-text;
        font-size: 12px;

        a {
            flex: auto;
            color: $brightness-7;
            margin: 8px 0 0;

            &:hover {
                text-decoration: underline;
            }

            @include mq(mobileMedium) {
                font-size: 14px;
                margin: 8px 10px 0 0;
            }
        }
    }
}

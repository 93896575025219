@mixin social-icon($platform) {
    background-color: $platform;
    &:hover {
        background-color: darken($platform, 10%);
    }
}

.social-icon--facebook {
    @include social-icon($social-facebook);
}

.social-icon--whatsapp {
    @include social-icon($social-whatsapp);
}

.social-icon--messenger {
    @include social-icon($social-messenger);
}

.social-icon--twitter {
    @include social-icon($social-twitter);
}

.social-icon--linkedin {
    @include social-icon($social-linkedin);
}

.social-icon--email {
    @include social-icon($brightness-46);
}

@import '../social-icons';


/* new audio styles hacked in for test page */

.content--media.new-audio {

    * {
        box-sizing: border-box;
    }
    figure#audio-component-container {
        margin-bottom: 12px;
    }
    @media (min-width: 1300px) {
        figure#audio-component-container {
            margin-bottom: 36px;
        }
    }
    ul {
        margin: 0;
        list-style-type: none;
        line-height: 1.5rem;
        img {
            display: inline-block;
        }
    }

    h1, h2, h3, li, p {
        color: #dcdcdc;
    }
    .player {
        line-height: 0;
    }

    @media (max-width: 46.24em) {
        .player {
            margin: 0 -.55rem;
        }
    }

    @media (min-width: 71.25em) {
        .player {
            margin-left: -10rem;
        }
    }
    @media (min-width: 81.25em) {
        .player {
            margin-left: -15rem;
        }
    }

}


/* original styles */


.content--media {
    .content__main-column {
        &::before {
            display: none;
        }
    }

    .content__head__comment-count {
        display: none;
    }

    @include mq($until: desktop) {
        .meta__comment-count--top,
        .meta__comment-count--bottom {
            .commentcount {
                border-top: 0;
            }
            .commentcount__label {
                display: none;
            }
        }
    }

    .player {
        margin-bottom: $gs-baseline/2;
    }

    .gu-media-wrapper {
        margin-bottom: 0;
    }

    &:not(.paid-content) {
        .social-icon {
            overflow: visible; // opacity cuts borders off on chrome otherwise
            box-sizing: border-box;

            &:hover,
            &:focus {
                background-color: transparent;
                border-color: $brightness-93;
            }
        }
    }

    .content__main-column--video {
        @include mq(desktop) {
            min-height: gs-height(12);
        }
    }

    .content__secondary-column--media {
        @include mq($until: desktop) {
            display: block;
            position: relative;
            width: 100%;
            max-width: gs-span(8);
            margin: 0 auto;
            padding-left: 0;
        }
        @include mq(tablet) {
            max-width: gs-span(9);
        }
        @include mq(desktop) {
            padding-left: $gs-gutter/2;
            border-left: 1px solid $brightness-20;
        }
    }
    .content__secondary-column--video {
        @include mq(desktop) {
            height: auto;
        }
    }
    .content__secondary-column--audio .ad-slot {
        display: none;

        @include mq(desktop) {
            display: block;
        }
    }
}

.content--has-body {
    // if it's audio and there's body text, we display an advert
    .content__main-column--audio {
        min-height: $gs-row-height * 9;
    }

    // increase height if we have sponsorship logos
    &.paid-content .content__main-column--audio {
        min-height: $gs-row-height * 9;
    }
}
.content--media--video .inline-video-icon {
    margin-right: .1em;
}

.items--audio .fc-item__title:before {
    @include icon(volume-high--tone-media, false);
}

/* Most viewed container
   ========================================================================== */

.most-viewed-container--media {
    border-top: 1px solid $brightness-20;

    @include mq($until: desktop) {
        margin-top: $gs-baseline*2;
    }

    .most-viewed-container__header {
        height: gs-height(1) + $gs-baseline;
    }
    .most-viewed-container__heading {
        @include fs-header(2);
        position: relative;
        top: $gs-baseline/4;
        color: $brightness-93;
    }
}
.most-viewed--media {
    margin-left: -$gs-gutter/2;
    margin-right: -$gs-gutter/2;

    .fc-item--media {
        float: left;
        width: 50%;
        padding: 0 $gs-gutter/2;

        @include mq($until: tablet) {
            &:nth-child(odd):before {
                display: none;
            }
        }

        @include mq(tablet, desktop) {
            width: 33.3%;

            &:nth-child(4n):before {
                display: none;
            }

            &:nth-child(1n+4) {
                margin-bottom: 0;
            }
        }

        @include mq(desktop) {
            &:nth-child(odd):before {
                display: none;
            }
            &:nth-child(n+5) {
                margin-bottom: 0;
            }
        }
    }
    .fc-item__title {
        @include text-clamp(3, get-line-height(headline, 1));
        height: get-line-height(headline, 1) * 3;
    }
}
.most-viewed--video .item:nth-child(n+5) {
    @include mq($until: tablet) {
        margin-bottom: 0;
    }
    @include mq(desktop) {
        margin-bottom: 0;
    }
}
.most-viewed--audio {
    .item:nth-child(n+3) {
        @include mq($until: tablet) {
            margin-bottom: 0;
        }
        @include mq(desktop) {
            margin-bottom: 0;
        }
    }
    .fc-item:nth-child(4) {
        @include mq(tablet, desktop) {
            display: none;
        }
    }
}

.content--video {
    @include mq(tablet, desktop) {
        .content__meta-container {
            float: left;
            width: 404px; //Magic, as video items below are off grid at 33.3% width
            margin-right: $gs-gutter;
            margin-bottom: $gs-baseline*2;
        }
    }

    .content__meta-container {
        border-bottom: 0;
    }
}


.most-viewed-container--playlist {
    .most-viewed__more-from {
        @include fs-textSans(1);
    }

    .most-viewed-container__heading {
        margin-bottom: $gs-baseline !important;
    }

    .most-viewed-container__header {
        height: auto !important;
    }

    .most-viewed-navigation {
        margin-top: $gs-baseline;
    }
    .most-viewed-navigation__button {
        display: block;
        width: 24px;
        height: 24px;
        text-align: center;
        border-radius: 24px;
        border: 0;
        background: #ffbb00;
        color: #333333;

        svg {
            fill: #333333;
        }
    }
    .most-viewed-navigation__button--prev {
        float: left;
    }
    .most-viewed-navigation__button--next {
        float: right;
    }
}


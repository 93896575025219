@import '../experiments/svg';


.contributions__option-button {
    @include fs-textSans(4);

    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: bottom;
    outline: none;
    border: 0;
    background-color: #ffffff;
    border-radius: 1000px;

    text-align: center;
    margin-left: $gs-gutter / 4;
    margin-right: 0;
    //This combines with the default height of the button to result in a
    //total button height of gs-gutter * 3
    padding-top: .5em;
    padding-bottom: .5em;

    &:hover {
        background-color: darken($highlight-main, 5%);
        color: $brightness-93;
    }

    &.active {
        background-color: $highlight-main;
        color: $brightness-7;
    }
}

.contributions__contribute {
    background-color: $sport-dark;
    color: #ffffff;
    font-weight: bold;
    margin-top: $gs-baseline;
    text-align: left;
    padding-left: $gs-gutter * (3 / 4);;
    padding-right: 0;
    margin-left: 0;

    &:hover {
        background-color: darken($sport-dark, 5%);
        text-decoration: none;
    }

    &:after {
        content: '';
        display: inline-block;
        background-repeat: no-repeat;
        width: 30px;
        height: 20px;
        background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path fill="white" stroke="white" d="M22.8 14.6L15.2 7l-.7.7 5.5 6.6H6v1.5h14l-5.5 6.6.7.7 7.6-7.6v-.9"/></svg>');
        background-size: 100%;
        float: right;
    }
}


.contributions__adblock {
    position: absolute;
    top: 6px;
    padding-top: $gs-baseline/2;
    width: 93%;

    .contributions__adblock-image {
        height: 100%;
        position: absolute;
        bottom: 0;
     }
}

//TODO This and the corresponding templates in discussion must be refactored to new fc-containers

.container__meta {
    padding-top: $gs-baseline/2;

    @include mq(tablet) {
        margin-bottom: $gs-baseline;
    }
    @include mq(desktop) {
        padding-top: $gs-baseline/2;
    }
    @include mq(desktop, leftCol) {
        max-width: 50%;
    }
    @include mq(leftCol) {
        margin-bottom: 0;
        float: left;
        clear: left;
        width: $left-column !important;
        margin-right: $gs-gutter;
    }
    @include mq(wide) {
        width: $left-column-wide !important;
    }
    .container__meta__title {
        @include fs-header(3);
        padding-bottom: $gs-baseline/3;
        line-height: get-line-height(header, 2);
        color: $brightness-7;

        @include mq($until: tablet) {
            padding: 0 0 $gs-baseline/3;
        }
        a {
            color: inherit;
        }
    }
    .container__meta__item {
        @include fs-headline(2);
        margin: 0;
        color: $brightness-46;
    }
}

/* Tweets

    Non-upgraded tweets are styles like the upgraded twitter embed
    so we have font-families and colours in here that are not part
    of the Guardian styles.
   ========================================================================== */

.element-tweet {
    margin: $gs-baseline 0 ($gs-baseline/3)*4;
}

.tweet,
.from-content-api blockquote.tweet {
    // We're going to style this like the upgraded embed
    font-family: Helvetica, Roboto, 'Segoe UI', Calibri, sans-serif;
    font-size: 14px;
    border: 1px solid $brightness-86;
    border-radius: 4px;
    padding: 20px;
    // End tweet embed styles

    display: inline-block;
    margin: 0;
    margin-bottom: $gs-baseline;
    font-style: normal;

    &:first-child {
        margin-top: $gs-baseline/2;
    }

    @include mq(tablet) {

        // forcing this max-width because that is the width of
        // the 'upgrade' from Twitter and we don't want it jumping
        // on upgrade
        max-width: 500px;

        margin-right: $gs-gutter;
    }
}

.tweet:before,
.tweet:after {
    display: none !important;
}

.tweet__user-name,
.tweet__user-id {
    display: inline-block;
    padding-left: 35px;
    margin-bottom: 0;
}

.tweet__user-name {
    display: block;
    font-weight: bold;
    position: relative;

    &:before {
        background-repeat: no-repeat;
        content: '';
        display: block;
        height: 21px;
        left: 0;
        position: absolute;
        top: 5px;
        width: 24px;
    }
}

.tweet__user-id {
    color: #697882; // We're going to style this like the upgraded embed
    margin-bottom: 20px;
}

.tweet-body {
    font-size: 16px;
}

.tweet-main-image {
    margin-bottom: $gs-gutter;
}

.tweet-date {
    display: block;
    color: $brightness-46;
}

.twitter-tweet {
    width: 100%;
}

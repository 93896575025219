@keyframes spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.collection__show-more--loading svg {
    animation: spinner linear 1.5s infinite;
}

.show-more__error-message {
    @include fs-textSans(3);
    transition: opacity 1.5s;
    color: $news-main;
    display: inline-block;
    padding-top: $gs-baseline / 2;
    opacity: 1;
}

.show-more__error-message--invisible {
    opacity: 0;
}

/**
 *  Everything below is pure magic. Trying to even out the different baseline of Guardian font.
 */
.collection__show-more {
    line-height: 2.2;
    font-size: 14px;

    @include mq(tablet) {
        margin-top: $gs-baseline;
    }

    .fc-show-more--hidden & {
        margin-top: 0;
    }

    .inline-minus {
        fill: $brightness-46;
    }

    .fc-show-more--visible & {
        .inline-plus {
            display: none;
        }
    }

    .fc-show-more--hidden & {
        .inline-minus {
            display: none;
        }
    }
}

.activity-stream {
    margin-top: $gs-baseline;
}
.activity-stream--loading:after {
    @include fs-textSans(4);
    display: block;
    content: 'Loading comments…';
    min-height: $gs-baseline*20;
    padding-top: $gs-baseline*2;
    text-align: center;
}
.activity-item {
    padding-bottom: $gs-baseline/2;
    padding-top: $gs-baseline/2;
    border-top: 1px solid $brightness-86;
}
.activity-item--first {
    border-top: 0;
}
.activity-item__title {
    @include fs-header(2);
    margin-bottom: $gs-baseline;
    font-weight: normal;

    @include mq(leftCol) {
        float: left;
        position: relative;
        z-index: 1;
        margin-right: $gs-gutter;
        width: $left-column;
    }

    @include mq(wide) {
        width: $left-column-wide;
    }
}
.activity-item__content {
    position: relative;

    @include mq(leftCol) {
        margin-left: $left-column + $gs-gutter;
    }

    @include mq(wide) {
        margin-left: $left-column-wide + $gs-gutter;
    }
}
.activity-item__content--boosted {
    margin-left: 0;
}
.activity-item__content-meta {
    @include fs-textSans(4);

    @include mq(activityLeftCol) {
        width: gs-span(2.5);
        float: left;
    }
}
.activity-item__content-body {
    @include mq(activityLeftCol) {
        margin-left: gs-span(2.5) + $gs-gutter;
        margin-right: $gs-gutter*3;
    }
}
.activity-stream__empty {
    @include fs-textSans(4);
    min-height: $gs-baseline*20;
    padding-top: $gs-baseline*2;
    text-align: center;
}
.activity-stream__pagination {
    border: 0;

    .pagination_legend {
        display: none !important;
    }
}
.activity-stream--discussions {
    .activity-item__content {
        padding-top: $gs-baseline/2;
        border-top: 1px solid $brightness-86;
    }

    .activity-item__content--no-border {
        border-top: 0;
        margin-top: 0;
        padding-top: 0;
    }
}

.activity-stream-search {
    margin-top: $gs-baseline/2;
}
.activity-stream-search__label {
    float: left;
}
.activity-stream-search__q-container {
    padding-left: $gs-gutter;
}
.activity-stream-search__q {
    @include fs-textSans(2);
    box-sizing: border-box;
    vertical-align: bottom;
    width: 100%;
    padding-bottom: $gs-baseline/6;
    padding-left: $gs-gutter/5;
    padding-top: $gs-baseline/3;
    border: 0;
    color: $brightness-46;

    &:focus {
        outline-color: $sport-bright;
    }
}

.activity-stream-content {
    border-top: 1px solid $brightness-86;

    @include mq(tablet) {
        margin-left: $gs-gutter;
        margin-right: $gs-gutter;
    }
}

.activity-stream__witness-contributions {
    padding-top: $gs-baseline*2;
}

.activity-stream__witness-item {
    @include fs-textSans(1);
    display: inline-block;
    width: 292px;
    margin-right: $gs-gutter/2;
    margin-bottom: $gs-baseline;
    vertical-align: top;

    a {
        text-decoration: none;
    }
}
.activity-stream__witness-img {
    width: 100%;
    height: 250px;
    background-size: cover;
}

.activity-stream__witness-meta {
    @include fs-textSans(1);
    background-color: $brightness-97;
    border: 1px solid $brightness-86;
    border-top: 0;
    text-align: right;
    padding: $gs-baseline 0;

    .activity-stream__witness-meta-inner {
        margin: 0 $gs-gutter;
        color: $brightness-7;
    }
}
.activity-stream__witness-content {
    border: 1px solid $brightness-86;
    border-top: 0;
    line-height: 14px;
}
.activity-stream__witness-content:hover {
    background: $brightness-93;
    transition: .3s all;
}
.activity-stream__witness-text {
    display: block;
    margin: $gs-baseline/2 $gs-gutter;
    min-height: 100px;
}
.activity-stream__witness-title {
    @include fs-textSans(4);
    margin-bottom: $gs-baseline/4;
    color: $brightness-7;
}
.activity-stream__witness-description {
    @include fs-textSans(2);
    color: $brightness-46;
}
